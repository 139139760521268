@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.blue-bedge{
  @apply bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300
}

.gray-bedge{
  @apply bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300
}
.red-bedge{
  @apply bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300
}
.green-bedge{
  @apply bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300
}
.yellow-bedge{
  @apply bg-[rgb(249,99,14)] text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-[rgb(249,99,14)] dark:text-white
}
.indigo-bedge{
  @apply bg-indigo-100 text-indigo-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300
}

.purple-bedge{
  @apply bg-purple-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300
}
.pink-bedge{
  @apply bg-pink-100 text-pink-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300
}







/* .btnP{
  @apply px-8 rounded py-3 hover:bg-blue-700 sm:w-24 w-full bg-primary text-white mr-2 text-md
} */
.spinTheChanges {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150;
}

.tdstyle {
  @apply p-2 border-r;
}

.card {
  @apply rounded-lg bg-white shadow-md;
}

.menuActive {
  @apply bg-blue-900 text-white;
}

.formControl {
  @apply text-sm w-full font-normal px-3 focus:outline-none py-2 rounded;
}

.activeInactive {
  @apply inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest outline-none ring disabled:opacity-25 transition;
}

.checkbox{

  @apply w-4 h-4  bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600
}

   /*!
* Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
* Copyright 2015 Daniel Cardoso <@DanielCardoso>
* Licensed under MIT
*/
 .la-ball-clip-rotate-multiple,
 .la-ball-clip-rotate-multiple > div {
   position: relative;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }

.la-ball-clip-rotate-multiple {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-clip-rotate-multiple.la-dark {
  color: #333;
}

.la-ball-clip-rotate-multiple > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-clip-rotate-multiple {
  width: 32px;
  height: 32px;
}

.la-ball-clip-rotate-multiple > div {
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 100%;
  -webkit-animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
  -moz-animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
  -o-animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
  animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
}

.la-ball-clip-rotate-multiple > div:first-child {
  position: absolute;
  width: 32px;
  height: 32px;
  border-right-color: transparent;
  border-left-color: transparent;
}

.la-ball-clip-rotate-multiple > div:last-child {
  width: 16px;
  height: 16px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  -o-animation-direction: reverse;
  animation-direction: reverse;
}

.la-ball-clip-rotate-multiple.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-clip-rotate-multiple.la-sm > div {
  border-width: 1px;
}

.la-ball-clip-rotate-multiple.la-sm > div:first-child {
  width: 16px;
  height: 16px;
}

.la-ball-clip-rotate-multiple.la-sm > div:last-child {
  width: 8px;
  height: 8px;
}

.la-ball-clip-rotate-multiple.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-clip-rotate-multiple.la-2x > div {
  border-width: 4px;
}

.la-ball-clip-rotate-multiple.la-2x > div:first-child {
  width: 64px;
  height: 64px;
}

.la-ball-clip-rotate-multiple.la-2x > div:last-child {
  width: 32px;
  height: 32px;
}

.la-ball-clip-rotate-multiple.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-clip-rotate-multiple.la-3x > div {
  border-width: 6px;
}

.la-ball-clip-rotate-multiple.la-3x > div:first-child {
  width: 96px;
  height: 96px;
}

.la-ball-clip-rotate-multiple.la-3x > div:last-child {
  width: 48px;
  height: 48px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-moz-keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -moz-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-o-keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -o-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    -moz-transform: translate(-50%, -50%) rotate(180deg);
    -o-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loadingIndicatorClass{
  display:flex;
  justify-content: center;
  align-items: center;
  background-color:rgba(0, 0, 0, 0.767);
  position:fixed;top:0px;
  left:0px;z-index:9999;
  width:100%;
  height:100%;
  opacity: .94;
}
.circle {
  display: inline-block;
  border: 2px solid blue;
  padding: 5px 10px;
  border-radius: 50px;
}

.iconselect__menu .iconselect__option--is-selected{ background-color: transparent !important;}
.iconselect__menu .iconselect__option--is-selected i { color: #000;}

.iconselect__menu .iconselect__option--is-selected:hover {
  background-color: #DEEBFF !important;
}