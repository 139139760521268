/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

button {
    outline: 0;
}


select { color: #121212;  }
select option{  color: #121212; visibility: visible;}

body{
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Poppins', sans-serif;
    background: #efeff4;
    font-size: 14px;
    color: #121212;
    -webkit-touch-callout: none;

}

input{ accent-color: #023a51;}

/*icons*/
.fa-sun-o:before {
    content: "\f185";
}
.fa-frown-o:before {
    content: "\f119";
}
.fa-money:before {
    content: "\f0d6";
}
.fa-clock-o:before {
    content: "\f017";
}
.MultiStepForm {
    --accent-color: #023a51;
  }

.max-wTd{ max-width:200px; text-overflow: ellipsis; overflow: hidden;}
.MultiFrom .hUjTly{
    background-color: #023A51 !important;
}

.MultiFrom .hUjTly::after{
    color: #023A51 !important;
}
.MultiFrom .npqWT{
background-color: #ba4706 !important;
}

.MultiFrom .npqWT::after{
 color: #ba4706 !important
}

.MultiFrom .kfYyRX{
background-color: #023A51 !important;
}

.MultiFrom .gXJfzC{
 background-color: #023A51 !important;
}
.MultiFrom .gXJfzC::after{
 color: #023A51 !important;
}

/* p{    font-family: 'Lato' } */
.font-medium{ font-weight: 500;}
a {
    outline: 0;
    color: #023a51;
}

.bg-primary{ background: #023A51;}
.bg-secondry{ background: #ba4706 ;}
.bg-secondry{ background: #ba4706 ;}
.bg-yellow-light{background: #f0d4c7; }
.bgprimaryExtralight{ background-color: hsl(195deg 18.18% 91.37%); color:#023A51 ;}
.bg-yellow-Elight{    background-color: hsl(20deg 60% 95.1%); color: #ba4706 ;}
.bg-yellow-light2 {  background: #fff2ed;}
.searchSec select{ width: 270px;}
.exportCsvClass{ padding: 9.5px 16px; display: block;}
.primary-text{ color:#023A51 ;}
.text-gray{ color:#A1A5B7 ;}
.secondry-text{ color: #ba4706 ;}

.react-datepicker-wrapper{ width: 100%;}
.redBedge { margin-right: 0.5rem; border-radius: 0.25rem; padding-left: 0.625rem; padding-right: 0.625rem;
    padding-top: 0.125rem;  padding-bottom: 0.125rem; font-size: 0.875rem;  line-height: 1.25rem; font-weight: 500;
    background-color: red;  color:#fff}
.greenBedge { margin-right: 0.5rem; border-radius: 0.25rem; padding-left: 0.625rem; padding-right: 0.625rem;
        padding-top: 0.125rem;  padding-bottom: 0.125rem; font-size: 0.875rem;  line-height: 1.25rem; font-weight: 500;
        background-color: green;  color:#fff}    
.amberBedge { margin-right: 0.5rem; border-radius: 0.25rem; padding-left: 0.625rem; padding-right: 0.625rem;
            padding-top: 0.125rem;  padding-bottom: 0.125rem; font-size: 0.875rem;  line-height: 1.25rem; font-weight: 500;
            background-color: #FFBF00; color:#000}            
input{ outline: 0;}
.dateRange .flex{border: 1px solid #e3e3e3; min-height: 42px; border-radius: 0.25rem; padding: 8px;}
.dateRange .flex input{ background: none;}
p{ font-size: 14px; opacity: .8;}
h1,h2,h3,h4,h5,h6{ color: #181C32; font-weight: 600;}
#root .full-screen-frame-widget-id {   background-color: #023a51a8 !important;}
.css-b62m3t-container .css-13cymwt-control{   background: #F9F9F9;     border: 1px solid #e3e3e3; min-height: 42px;     font-size: 0.875rem;}
.css-b62m3t-container .css-1fdsijx-ValueContainer{ padding-left: 1rem;   padding-right: 1rem;}
.css-b62m3t-container .css-qbdosj-Input { display: none; }
.css-1fdsijx-ValueContainer i{  font-size: 16px;}
.css-tr4s17-option{  font-size: 16px;}
form h4{ font-size: 15px;}
.actionBtn{ color: #023A51;border:1px solid #023A51; transition: all 0.5s; min-width: 43px;
    -webkit-transition: all 0.5s; text-align: center; padding: 5px 13px; margin-right: 4px; display: inline-block; border-radius: 4px; cursor: pointer; }
.actionBtn:hover{ background: #023A51 ; color: #fff; transition: all 0.5s;
    -webkit-transition: all 0.5s; }
.actionBtn i{ min-width: 12px;     font-size: 14px}
.actionBtn.disabled{ cursor: not-allowed;  pointer-events: none;  border: 1px solid #ddd; color: #ddd;}
#root .css-1s2u09g-control{ min-height: 46px !important;}
#root .css-1rhbuit-multiValue {
    background-color: hsl(21.67deg 93.75% 37.65%);}
#root .css-xb97g8{ color: #fff;}
#root .css-12jo7m5{color: #fff;}
.max-w-th{ max-width: 130px;}

.content .css-1s2u09g-control{ min-height: 46px !important;}
.content .css-1rhbuit-multiValue {
    background-color: hsl(21.67deg 93.75% 37.65%);  
color: white;}
.content .css-xb97g8{ color: #fff;}
 .content .css-12jo7m5{color: #fff;}
/* .fc-event-start::before{ color: #000; margin-right: 5px;} */
 /* .settingTabArea .Toastify .Toastify__toast--success{ background: #07bc0c !important;  color: #fff !important;}
 .Toastify__progress-bar--success{} */
/**/
.fc td, .fc th{ cursor: pointer;}
.onboardPopup-content .btn.save{font-size: 16px; }
.loginImage{    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;}
    
.loginImage .userImage{ width: 440px; margin: 0 auto;}
.signupPage .formBlock {
    display: block;  
    font-size: 14px;}
    .otpSection > div{ width: 100%; justify-content: center;}
.otpInput{ width: 40px !important; height: 40px;     font-size: 18px; border-radius: 4px; border: 1px solid #A1A5B7; margin: 0 6px;}    

.signupPage .formControl{padding: 6px 10px; min-height: 40px; font-size: 14px;}
.eyeIcon{ position: relative;}
.eyeIcon i{    position: absolute; right: 10px; bottom: 13px; opacity: 1; background: #fff;  color: #121212;}

#popup-root .popup-content.smallModal-content { max-width: 380px;}

#sfu-iframe-wrapper .btn-primary{

    background-color: #023A51 !important;

}
#widget #sfu-iframe-wrapper button
{

    background-color: #023A51 !important;

}



.dashCardIcon{     width: 85px;
    height:75px;
    background: #fff;
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgb(201 71 6) 0%, rgb(2 58 81) 100%);
    border-radius: 12px;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 35px;
   
    
    box-shadow: 1px 4px 7px #0e3b4d4a;
    top: -15px;
    }
    /* .approvalsIcon{background: #023a51;   box-shadow: 1px 4px 7px #023b514b; } */
/* .infoList .heading_title{background: #023a51; padding: 12px 16px; color: #fff;border-radius: 4px 4px 0 0;} */
.infoList{ padding: 0; border: 1px solid #023a51;     min-height: 300px;}
.infoList .heading_title{  position: relative; background: #023A51;
       /* background: #c94706;color: #fff; */
    /* background: #023A51; */
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(201 71 6) 0%, rgb(2 58 81) 100%); */
  
    border-radius: 6px 6px 0 0;}
    
.infoList .heading_title h3{ font-size: 14px; color: #fff;  }
.heading_title i{color: #A1A5B7;}
 .cardtabBtn { display: flex;  padding: 0 0 5px; }
   .cardtabBtn .btn-sm{ padding:4px 8px; border-radius: 4px; font-size: 13px;}
   .superAdminTabs .cardtabBtn .btn-sm{  font-size: 16px; width: 20%;}
    .cardtabBtn .btn-sm.active{color: #c94706; font-weight: 600;}
.cardtabBtn .btn-sm{   font-weight: 500; background: #efefef; opacity: .5;} 
.cardtabBtn .btn-sm.active{  
    color: #c94706;
    padding: 4px 8px;
    background: #c9470626; 
    opacity: 1;
}  
  .infoList .table th {
    /* background-color: #023a51;
    font-weight: 500;
    color: #fff; */
    background-color: #eff2f7;
    font-weight: 600;

}
.subHeader{
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 0; width: auto; height: 52px;
}
.subHeader img{ height: auto;  padding: 0 2px;  min-width: 50px; width: auto; max-height: 52px ; max-width: 120px; object-fit: contain;}
.subHeader a{ display: flex; align-items: center; justify-content: center;}
.cardMaxHeight1{max-height:488px; overflow-y: auto;}
.messageBox{ min-height: 560px;}
.OpenMeassage p{ margin-bottom: 12px; line-height: 24px;}
.infoList ul li{ padding: 5px 28px; display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #0000000a;}
.infoList ul li .userInfoList{ display: flex; align-items: center;}
.listImage{  position: relative;   }
.listImage img{ min-width: 48px;  width: 48px; height: 48px; margin-top: 0;  background: #c9470621;  padding: 4px; margin-right: 16px;}
.infoList ul li h5{ margin-right: 4px; font-size: 14px;}
.infoList .tabContent{  height: 195px;  overflow-y: auto;}
.max-h194{  height: 198px;  overflow-y: auto;}
 .infoList .table td, .infoList .table th{    padding: 6px 8px;} 
.infoList .listImage img {  min-width: 40px;  width: 40px;  height: 40px;}
.cardMaxHeight{ height: 210px;  overflow-y: auto;}
.cardMaxHeight2{ height:251px;  overflow-y: auto;}
/* background: linear-gradient(46deg, #000000 50%, #F44336 51%); */
.loginTracker{
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding: 0rem 0px;
    margin-top: 0rem;
    margin-bottom: 0rem;
}
.loginTracker li{    list-style: none;
    display: flex;
    position: relative;
    justify-content: flex-start !important;
    min-height: 70px; border-bottom: 0 !important; padding: 0 6px !important; align-items: inherit !important;
}
.loginTracker li .line{
    display: flex;
    flex-direction: column;
    flex: 0 1 0%;
    -webkit-box-align: center;
    align-items: center;
}

.line span:not(.lineConnector){
    display: flex;
    align-self: baseline;
    border-style: solid;
    border-width: 2px;
    padding: 4px;
    border-radius: 50%;
    margin: 11.5px 0px;
    border-color: transparent;
    color: rgb(255, 255, 255);
    background-color: rgb(33 128 167);
    box-shadow: rgb(2 58 81 / 17%) 0px 0px 0px 4px;
    min-width: 26px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.loginTracker li:nth-child(odd) .line span:not(.lineConnector){     background-color: #c94706;
    box-shadow: #ff572229 0px 0px 0px 4px;}
    .loginTracker li:last-child .line .lineConnector{ display: none;}
.line .lineConnector{
    width: 2px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    background-color: rgba(50, 71, 92, 0.12);
}
.trackContent{ padding: 6px 16px;     margin-bottom: 16px;}

.fc-event.fc-event-draggable{ display: flex; align-items: center; padding: 0 5px;     border: 0 !important; color: #fff !important; }
.fc-event.fc-event-draggable a{ color: #fff !important; }
.fc-event.fc-event-draggable a span{ font-family: "Poppins, sans-serif"}
.fc-event.fc-event-draggable div{ width: 100%;}
.fc-event.fc-event-draggable div > div{ display: flex; align-items: center;     }
/* option:checked{
    color: rgb(246, 250, 0);
    background-color: rgb(5, 26, 1);
  } */

/* #popup-root > div{ z-index: 9999999999999999999 !important;} */
.Select-option.is-selected {
    color: red !important;
}
/* .fc-event-main, .undefined{ font-family: "Poppins, sans-serif"} */
/* .fc-event-draggable::before{ display: none;} */
/*onboardPopup*/

/*onboardPopup*/   
.onboardContent h3{ font-size: 15px;}
.onboardPopup-overlay{     backdrop-filter: blur(4px);}
.onboardPopup-content .modal > .content{  max-height: 100%;}
.multiNav{min-width: 250px; background-color: #fff; width: 100%;  border-radius: 4px 0 0 4px; height: 100%; padding:16px; position: relative;    box-shadow: 2px 0px 9px #0000001c; min-height: 580px;}
.multiContent{ background-color: #f4f4f4; width: 100%; height: 100%; padding: 24px; max-height: 580px; overflow-y: auto; border-radius: 0 4px 4px 0 ;}
.onboardingLink ul{ padding: 0; margin: 40px 0 0 0;}
.multiContent .overflow-x-auto{ max-height: 220px;}
.multiContent .table th {    background-color: #ededed; font-weight: 600;}
.multiContent .formControl{ background-color: #fff;}
.onboardingLink ul .dropdown ul{ margin: 0px 0 0 10px; display: none;}
.onboardingLink ul li.dropdown.show ul{ display: block;}
.onboardingLink ul li.dropdown.show > span{ color: #ba4706;   font-weight: 600;
    background: #fff;}
    .onboardingLink ul li.dropdown ul li .stepnum {
        font-style: normal;
        width: 20px;
        color: #181C32;
        height: 20px;
        border: 1px solid #181c324f;
        display: inline-block;    font-size: 12px;
        border-radius: 50%; margin-right: 5px;
        text-align: center;

    }
    .onboardingLink ul li.dropdown ul li .active .stepnum{    color: #ba4706;  border: 1px solid #ba4706; }
    .onboardingLink ul li.dropdown ul li .check .stepnum {
display: none;
    }
    .onboardingLink ul li.dropdown ul li .check  .fa-check {
        font-style: normal;
    width: 20px;
    height: 20px;
    border: 1px solid #ba4706;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    background: #ba4706;
    color: #fff;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-size: 12px;

    }
.onboardingLink ul ul li span{ font-size: 14px; padding: 7px 10px; text-transform: capitalize;}
.onboardingLink ul li{ font-size: 16px; color: #414141; margin-bottom: 4px;}
.onboardingLink ul li span{  width: 100%;
    display: block;
    padding: 10px 10px;     color: #181C32;
    border-radius: 4px; cursor: pointer;
  }
  
.onboardingLink ul li .active{ color: #ba4706;   font-weight: 600;
    background: #fff;}
    .onboardingLink ul li .active .fa-check{ display: none;}
/* 
.MultiFrom .NLdcB:after{color: #023a51; }
.MultiFrom .NLdcB{background-color: #023a51;}
.MultiFrom .sc-bgqQcB:not(.fYQjsy){ background-color: #023a51;}
.MultiFrom .sc-bgqQcB:after:not(.fYQjsy){color: #023a51; }
.MultiFrom .sc-gTRrQi{ background-color: #023a51;}
.MultiFrom .sc-bgqQcB:after{color: #023a51; }
.MultiFrom .fYQjsy::after{ color: #cdcdcd;}

.multiContent .jSqRdO{background-color: #023a51;}
.multiContent .eokxJX{background-color: #023a51;}
.multiContent .eokxJX:after{color:#023a51; }
.multiContent .jSqRdO:after{color:#023a51; }
.multiContent .coeqzW{background-color:#023a51; }
.multiContent .coeqzW:after{color:#023a51; }
.multiContent .kwDHmn{background-color:#023a51; }
.multiContent .kwDHmn:after{color:#023a51; }

.multiContent .iMxSLc{background-color:#023a51; }
.multiContent .iMxSLc:after{color:#023a51; }

.multiContent .bbndcq{background-color:#023a51; }
.multiContent .bbndcq:after{color:#023a51; }
.multiContent .cEyxgh{background-color:#023a51; }
.multiContent .cEyxgh:after{color:#023a51; } */

.an-simple-select__option.an-simple-select__option--is-selected {
    background-color: white;
    color: red;
  }
  .an-simple-select__option:hover {
    background-color: green;
  }


.onboardingTab .sc-bcXHqe {
    width: 25px !important;
    height: 25px !important;
}

.sContentOnboard{    background: #f2f2f2;
    padding: 25px;
    margin-top: 56px;
    border-radius: 6px;}

.onboardingTab{ min-height: 300px;}
.onboardingTab .kqTjny{ background-color:#023a51; }
.onboardingTab .kqTjny:after{ color:#023a51; }
.onboardingTab .sc-bcXHqe{ width: 15px; height: 15px;}
.onboardingTab .sc-bcXHqe.kqTjny {
    width: 25px;
    height: 25px;
}
.onboardingTab .sc-bcXHqe.nfKdy {
    width: 25px;
    height: 25px;
}
.onboardingTab .sc-bcXHqe:after{ font-size: 15px !important; text-transform: capitalize; bottom: -35px; font-weight: 600;}
.onboardingTab .bEwkfb:after{color: #023a51; }
.onboardingTab .bEwkfb{ background-color:#023a51;     width: 25px;
    height: 25px;}

.onboardContent ul { margin-top: 32px;}
.onboardContent ul li { margin-bottom: 16px;}
.onboardContent ul li span{ width: 42px; height: 42px; border-radius: 50%;    color: #023a51;
     background-color: #fff; font-size: 20px; text-align: center; line-height: 42px; box-shadow: 1px 2px 3px #00000052;}

.dorpdownItem span{ color: #c94706;}
  .dorpdownItem label{ position: relative; cursor: pointer;}
  .dorpdownItem label input{ position: absolute; width: 100%; opacity: 0; height: 100%; z-index: 1111;}
  .dorpdownItem label input:checked + span{ color: red;}
  .dorpdownItem span{ display: none; font-size: 16px;   }
  .dorpdownItem:hover span{ display: block;}
  .dorpdownItem span.fa-star {display: block !important;}
  .dorpdownItem span{  transition: all 0.5s; -webkit-transition: all 0.5s; }
  .dorpdownItem:hover span.fa-star-o{ transform: scale(1.5);      transition: all 0.5s;
    -webkit-transition: all 0.5s;} 

  .dorpdownItem span.fa-star {display: block !important;}

  .dorpdownItem span{  transition: all 0.5s; -webkit-transition: all 0.5s; }
  .dorpdownItem:hover span.fa-star-o{ transform: scale(1.1);      transition: all 0.5s;
    -webkit-transition: all 0.5s;} 


#popup-root .onboardPopup-content {max-width: 1100px !important;}
#popup-root .workScheduleOnBoardPopup-content {max-width: 810px !important;}
#popup-root .workScheduleOnBoardPopup-content .workingSchduleForm h3{ font-size: 14px;}

.onboardingTab .employeeTab ul { justify-content: space-between;}
/* .onboardingTab .tabCard { max-width: 720px; margin: 0 auto;} */
.onboardingTab .employeeTab ul li span{ padding: 12px 0px;}
.onboardingTab .employeeTab ul li span.active{ font-weight: 600;}
.fab-container{ right: 4vw !important; z-index: 99999; }
.fab-item{  background: #023A51 !important}
.fab-item:nth-last-child(1){  background:  #c94706 !important}



header{ min-height: 69px; align-items: center;  justify-content: center;  display: flex; padding: 10px 0;}
.bell{    background: #ffffff4f;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;}
    .bell i {color: #fff;
        font-size: 14px;}

        .bell.active i{ color:#ba4706}
        .bell.active { background: #fff;}
.logo{
    padding: 0 0px;
    margin-right: 12px;
  
    display: flex; width: 200px;
    align-items: center;}
.headerNav{position: relative;  }
.headerNav ul li{    padding: 0; margin-right: 10px;  position: relative; }
.headerNav ul li a{ padding:8.5px 10px ; border-radius: 7px; color: #ffffffbf; font-size: 14px;     align-items: center;
    display: flex;}
.headerNav ul li a.active{ background-color: #fff; color: #ba4706; font-weight: 600; }
.headerNav ul li a:hover{ background-color: #fff; color: #ba4706;  }
.headerBottomNav ul li a:hover{ background-color: #fff; color: #ba4706;  }
.headerLeft .nav ul li .bell:hover{ background-color: #fff; color: #ba4706 !important;  }
.headerLeft .nav ul li a:hover i{ color: #ba4706 !important; opacity: 1;  }
.headerLeft .nav ul li .bell:hover i{ color: #ba4706 !important;  }
.headerLeft .nav ul li .notification a:hover i { color:#023A51 !important ;}
.headerNav ul li a span{ margin-right: 5px; font-size:15px;}
.headerBottomNav ul li a span {  margin-right: 5px;}
.headerBottomNav { padding: 4px 0; display: flex; justify-content: space-between; align-items: center;} 
.headerBottomNav ul li a{color: #7E8299;  font-weight: 500;  font-size: 13.5px;  padding: 16px 15px;  display: block;}
.headerBottomNav ul li a.active{    color: #ba4706;}
input[type=checkbox]:checked {
    background-color: #a77e2d !important;
    color: #ffffff !important;
  }
  .headerRight ul li .notification li span.w-6.h-6{ min-width: 24px;  }
.headerNav ul li ul{    display: none;
    z-index: 98;
    position: absolute;
    top: 100%; width: 225px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    border-radius: 4px;
    padding: 20px 0px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 15px 50px 0px rgba(82, 63, 105, 0.15);

}
.headerNav ul li:hover ul{
    display: block;
    -webkit-animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;
    animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;
}
.headerNav ul li .subMenu li a{ color: #333; padding: 11px 16px;}
@-webkit-keyframes header-menu-submenu-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes header-menu-submenu-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes header-menu-submenu-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes header-menu-submenu-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes header-menu-submenu-move-up {
    from {
      margin-top: -10px;
    }
    to {
      margin-top: 0;
    }
  }
  @keyframes header-menu-submenu-move-up {
    from {
      margin-top: -10px;
    }
    to {
      margin-top: 0;
    }
  }
  @-webkit-keyframes header-menu-submenu-move-down {
    from {
      margin-top: 10px;
    }
    to {
      margin-top: 0;
    }
  }
  @keyframes header-menu-submenu-move-down {
    from {
      margin-top: 10px;
    }
    to {
      margin-top: 0;
    }
  }
  #popup-root .popup-content {
    max-width:680px;
    padding: 0;
    min-height: 220px;
    border: 0;     width: 100%;
    border-radius: 8px;
}

#popup-root .max-w-lg-content{ max-width: 620px; width: 100%;}
#popup-root .popup-content .searchWrapper {
    min-height: 46px;
    border-color: #eaeaea;
    box-shadow: 3px 4px 4px #802bca14
}
.headerQuickAdd{ position: relative;}
.headerQuickAdd:hover .quickAddBox{ display: block;}
.quickAddBox{background: #023a51; display: none;
    padding: 16px;
    border-radius: 6px;
    color: #fff;
    position: absolute;
    right: 0; z-index: 555;
    top: 100%;     box-shadow: 1px 6px 7px #023a515e;
    min-width: 170px;}
    .quickAddBox.show{ display: block;}
    .quickAddBox ul li{ line-height: 36px;}    
    .quickAddBox ul li span{ font-size: 16px;     min-width: 18px;}

    .moreBox{background: #fff; display: none;
        padding: 8px 16px;
        border-radius: 6px;
        color: #414141;
        position: absolute;
        right: 30px; z-index: 555;
        top: 15px;     box-shadow: 0px 1px 7px #0a0a0a36;
        min-width: 170px;}
        .moreBox.show{ display: block;}
        .moreBox ul li{ line-height: 36px; padding: 0;}    
        .moreBox ul li span{ font-size: 16px;     min-width: 18px;}
        .moreBox ul li:last-child{ border-bottom: 0; }
        .moreBox.show{ display: block;}
  .notification{    
    display: none;
    z-index: 98;
    position: absolute;
    top: 90%; width: 250px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    border-radius: 4px;
    padding: 8px 0px;
    background-color: #fff;
    -webkit-box-shadow: 0px 15px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 15px 50px 0px rgba(82, 63, 105, 0.15);
    right: 0px;
    -webkit-animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;
    animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;

}
.notification li{ position: inherit !important; padding: 0 !important;}
.notification li a{ padding: 7px 18px; display: block; color: #212121;     border-bottom: 1px solid #cdcdcd;}
.notification li h3{ margin-bottom: 4px;}
.notification li p{ font-size: 13px;}
.nav ul li{ padding: 8.5px 0; position: relative;}
.nav ul li:hover .notification {
    display: block;
    
    -webkit-animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;
    animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;
}

.rbc-toolbar .rbc-toolbar-label{ font-weight: 600;}
.rbc-header{     padding: 16px 4px !important; }
.approvalSubTab{    border-bottom: 1px solid #023a51; }
.approvalSubTab li{    border-right: 1px solid #e7e7e7;}
.approvalSubTab li:last-child{ border-right: 0;}
.approvalSubTab li span{ padding:9px 16px ; border-radius: 4px 4px 0 0;  font-size: 15px;     color: #A1A5B7;    align-items: center;
    display: flex; cursor: pointer; background: #f8f8f8;}
.approvalSubTab li span.active { background-color: #023A51; color: #fff; font-weight: 600; }
.approvalSubTab li span img{ margin-right: 8px;}

.sidebarnav{ background-color: #001c44;}
.tabList{background: #0000000d; }
.tabList ul li{ margin-bottom: 12px;}
.tabList ul li a{ font-size: 15.5px; position: relative; color: #4f536c; padding: 12px 10px 12px 40px; display: block; cursor: pointer;     border-radius: 4px; }
.css-tj5bde-Svg{ color: #000;}
.tabList ul li a span{      margin-right: 8px; font-size: 18px;  position: absolute;top: 14px;  left: 10px;}
.tabBtn { border-left: 2px solid transparent;}
.tabBtn-active {  background: #ba4706 !important; color: #fff !important;  font-weight: 700;}
.tabList ul li a:hover{  background: #ba4706 !important; color: #fff !important;  font-weight: 700;}
textarea{ min-height: 100px;}
.formControl.p-0{ padding: 0; }
.react-datepicker__view-calendar-icon input{ padding: 0 1rem !important ;}
.formControl{ min-height: 42px;  height: 42px;  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;     border: 1px solid #e3e3e3;}
.formControl:focus {
    color: #3F4254;
    background-color: #ffffff;
    border-color: #023A51;
    outline: 0;
  }
.formBlock {  display: block;  margin-bottom: 0.5rem;  font-size: 14px;  color: #3f4254; font-weight: 500;}
.formBlock.flex{ display: flex;}
.card2{box-shadow: 0px 0px 3px #0000001c;  border-radius: 6px;}
.imageUpload{ width: 80px; position: relative; height: 80px; border-radius: 4px; border: 1px solid #f5f5f5; display: flex; align-items: center; justify-content: center;}
.imageUpload img{ width: 100% !important; height: 60px;     object-fit: cover;}
.imageUpload span{  position: absolute;
    right: -12px;
    top: -12px;
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 1px 2px 3px #00000030;
    width: 30px;
    height: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageUpload .file-upload{ display: none; cursor: pointer;}
.imageUpload .file-upload i{ margin-bottom: -50px;     animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1;}
.imageUpload:hover .file-upload{ display: flex; transition: all 0.5s; -webkit-transition: all 0.5s; -ms-transition: all 0.5s;}
.imageUpload:hover .file-upload i{ margin-bottom: 0px;    animation: header-menu-submenu-fade-in 0.3s ease 1, header-menu-submenu-move-down 0.3s ease-out 1; }
.PhoneInputInput{ background-color: #F9F9F9;}
.heading_title{border-bottom: 1px solid #ededed;}
.btn{ padding: 10px 20px; border-radius: 4px; min-width: 120px; color: #fff;  transition: all 0.5s;
    -webkit-transition: all 0.5s;  min-height: 47px;}
.btn.save{ background-color: #023a51;}
.btn.save:hover{ transition: all 0.5s; -webkit-transition: all 0.5s; opacity: .9; }
.btn.cancel{ background-color: #ba4706;}
.btn.cancel:hover{  transition: all 0.5s; -webkit-transition: all 0.5s; opacity: .9;}
.btn-md{ padding: 4px 16px; border-radius: 3px; min-width: 126px;     line-height: 32px; color: #fff ; font-size: 14px;     min-height: 42px;}
.btn-add{ border:1px solid #023A51; color: #023A51;}
.btn-add:hover{  background-color: #023a51; color: #fff; transition: all 0.5s;
    -webkit-transition: all 0.5s; } 
.formControl.bg-white{ background-color: #fff; }
.table th { background-color: #f9f9f9; font-weight: 500;     vertical-align: baseline; }
.table td, .table th { padding: 0.75rem;font-size: 14px; text-align: left;}
.table td.text-center{ text-align: center;}
.table th.text-center{ text-align: center;}
.table tbody tr{ border-bottom: 1px solid #f9f9f9;}
.modal .content p{ font-size: 14px;}
button.px-8.rounded.py-3.hover\:bg-red-500.sm\:w-24.w-full.bg-red-500.text-white.text-md{
    min-width: 120px;
}
button.px-8.rounded.py-3.hover\:bg-blue-700.sm\:w-24.w-full.bg-primary.text-white.mr-2.text-md{
    min-width: 120px; 
}
.calendarContent .table td, .calendarContent .table th { padding: 0.5rem;}
.calendarContent .table tr{ border: 0;}
.info{ font-size: 13px; }
.info i{    margin-right: 4px;
    border: 1px solid #cdcdcd;     font-size: 10px;
    padding: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    text-align: center;
}

.filter{
    background: #023A51;
min-height: 40px;
padding: 8px 16px;
border: 1px solid #000;
border-radius: 4px;
border: 1px solid #023A51;
color: #ffff;
}

.employeeTab{ margin-top: 6px;}
.employeeTab ul{ display: flex; margin-top: 6px;}
.employeeTab ul li {margin-right: 32px; cursor: pointer;} 
.employeeTab ul li span{color: #A1A5B7; font-weight: 500; display: block; font-size: 15px;  padding: 16px 0px; border-bottom: 2px solid transparent;}
.employeeTab ul li span.active{ opacity: 1; color: #023A51;  border-bottom: 2px solid #023A51;}

.approvalTab ul li span{ display: inline-flex; align-items: center;}
.approvalTab ul li span span{ padding: 0 5px 0 0; }
.approvalTab ul li span span img{ width: 20px;}
.approvalTab ul li span pre{ font-weight: 600;}

.approvalTab ul li span.active span {
    color: #023A51;
}
.currentYear input{ font-size: 16px;}
.currentYear p{ font-size: 15px;}
.currentYear{    background: #ba470617;  padding: 16px; border-radius: 4px;}

.tabContent .workingSchduleForm p{ margin-bottom: 0;}
/* .workingSchduleForm select{ opacity: .5; cursor: none; font-size: 14px;}
.workingSchduleForm span{ opacity: .5; cursor: none; font-size: 14px;}
.workingSchduleForm input:checked + select { opacity: 1; cursor:default;}
.workingSchduleForm input:checked + select + span {opacity: 1; cursor:default; }
.workingSchduleForm input:checked + select + span + select{ opacity: 1; cursor:default;} */

/*checkbox*/
.checkbox_custom input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkbox_custom label {
    position: relative;
    cursor: pointer;
    font-size: 15px;
  }
  
  .checkbox_custom label:before {
    content:'';
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #d3d3d3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right:10px;
  }
  .checkbox_custom input:checked + label:before{
    border: 2px solid #0079bf;
  }
  .checkbox_custom input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 8px;
    width: 8px;
    height: 16px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .input_file{
    padding: 30px 20px;
    border: 1px solid rgba(0,40,100,.12);
    position: relative;
    cursor: pointer;
    min-height: 40px;
    display: flex;
    align-items:center;
    justify-content: center;
}
.input_file input{
   font-size:0;
   position: absolute;
   width: 100%;
   height: 100%;
   cursor: pointer;
   z-index: 1;
}

.fileUploadInPopup  .upload-button{   padding: 8px 20px !important;}
.upload-wrapper .upload-button{

    padding: 30px 20px;
    border: 1px solid rgba(0,40,100,.12);
    position: relative;
    cursor: pointer;
    min-height: 40px;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 15px;
    width: 100%;
    border-radius: 4px;
}

.input_file input[type=file]::file-selector-button {
  border: 20px solid #6c5ce7;
  padding: .2em .4em;
  border-radius: .2em;
  background-color: #000;
  transition: 1s; visibility:hidden;
  font-size:16px
}
.input_file:after{
  content:"Upload File";
  position:absolute;
  left:0;
  text-align: center; 
  font-size: 16px;
  right: 0;
  font-weight: 600;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.divcurrentPlan{ border:2px solid #023a51; color: #023a51; background: #023a51; color: #fff;}
.divcurrentPlan .bg-primary{ background: #ba4706;}
.divcurrentPlan h2{ color: #fff;}
.divcurrentPlan h5{ color: #fff;}
.divcurrentPlan .bg-secondry{ background: transparent; border: 1px solid #ba4706; color: #ba4706;}
.selectPlan label{ cursor: pointer;}
.selectPlan label:hover .freePlan  {     border: 2px solid #023a51;   }

.currentPlan{ background: #023a51; position: absolute; font-weight: 300; font-size: 13px;
     bottom: 0 ; width: 100%; left: 0; padding: 12px 32px; color: #42ebac }
.currentPlan span{ background-color: #42ebac; color: #000;}

.workingSchduleForm .w-5 {     min-width: 1.25rem;}
.formBlock input{ min-width: 20px;}
.calendar .calendar-header .year-title{font-weight: normal !important;   }
.calendar table.month th.month-title{ font-weight: normal;     font-size: 14px !important;}
.calendar .months-container .month-container{ height: 280px !important;}
.calendar table.month th.day-header{ font-weight: normal;     font-size: 12px !important;}
.daysRemaning table{ font-size: 14px;}
.calendar .calendar-header{ border: 0 !important;}
.calendar .calendar-header .year-neighbor{ display: none;}
.calendar .calendar-header .year-neighbor2{ display: none;}
.calendar .calendar-header table th:hover{ background: none !important;}
.dM{ padding: 0 !important;}
.dr ._1g {
    font-weight: normal;
}
.dr ._2E {
    color: #333;
    background: #023a510d;
    /* background: #FFC107; */
}

.dr ._2E .Lx {
    color: #333;
}
.jb{ font-weight: 600 !important;}
.card ._3n{ min-width: 30px; line-height: 40px;}
.onlyDates .jb { display: none !important;}
.onlyDates .Lx { display: none !important;}

.fc .fc-daygrid-day-top {
  
    justify-content: center;
}
.fc .fc-view-harness{ height: auto !important;}
.fc .fc-view-harness-active>.fc-view{ position: inherit !important;}

.fc .fc-multimonth-title{ font-weight: 500 !important;}
.fc-multiMonthYear-view a{ color: #121212;}
.fc-multiMonthYear-view .fc-more-link{ color: #ba4706;
    font-weight: bold; }
.calendarTable th{ font-weight: 500; color: #7E8299 ; text-align: center; padding: 0 0 24px 0; min-width: 25px}
.calendarTable td{ font-weight: 300; color: #7E8299; text-align: center; min-width: 25px; padding: 0;}
.calendarTable td span{ text-align: center; min-height: 37px; display: flex; border-right: 1px solid #fff;  align-items: center; justify-content: center; }
.prev span{ position: relative; font-size: 0;     opacity: .5;} 
.next span{ position: relative; font-size: 0;     opacity: .5;}
.next span::after{ content: "\f061"; position: absolute; font-size: 24px; right: 250px;     font: normal normal normal 24px/1 FontAwesome; }
.prev span::after{ content: "\f060"; position: absolute; font-size: 24px; left: 250px;     font: normal normal normal 24px/1 FontAwesome; }
/* .day-header:nth-child(1){ background-color: #0000000d;} */
.month tr td:nth-child(1) { background-color: #0000000d;}
/* .day-header:nth-child(7){ background-color: #0000000d;} */
.month tr td:nth-child(7) { background-color: #0000000d;}
.calendar table.month tr td, .calendar table.month tr th {
    padding: 5px !important;
}

.fc .fc-col-header-cell-cushion {
    
    padding: 12px 0px !important;
}
.weekOf{ background-color: #f1f1f1; }
th.fc-day-sat{ background: #fff;}
th.fc-day-sun{ background: #fff;}
.fc-day-sat{ background: eeeee; cursor: no-drop;}
.fc-day-sun{ background: eeeee; cursor: no-drop;}
.fc .fc-cell-shaded, .fc .fc-day-disabled {
    background: hsl(0deg 0% 100% / 30%);
}

/* 
.fc-day-sat{ background: #fff0e8; cursor: no-drop; color: #ba4706;}
.fc-day-sun{ background: #fff0e8; cursor: no-drop; color: #ba4706;}
.fc-multiMonthYear-view .fc-day-sat a{ color: #ba4706;}
.fc-multiMonthYear-view .fc-day-sun a{ color: #ba4706;} */

.calendarTable .holidayOff{ background: #ba4706 ; font-size: 0;}
.calendarTable .holidayOff span{ font-size: 14px; color: #fff;}
.listImage span{
    position: absolute;
    display: none;
    background: #023a51;
    border-radius: 4px;
    width: 44px;
    height: 24px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 25px;
    left: 0;
    top: -8px;
    font-weight: 500;

}
.card .calendar{ overflow-x: visible;}
.addMoreRow:first-child .removeBtn{ display: none;}
.calendarContent{ box-shadow:0 1px 3px 2px rgb(0 0 0 / 0.1);      margin: 58px 0 0 0;}
.calendarContent:nth-child(2){ margin-top: 24px;}
.calendarContent:nth-child(3){ margin-top: 24px;}
/* .calendarContent h3{ font-weight: 500;} */
.calendarContent .table th{ background: none;}
.calendarContent .totalTr td{ border-top: 1px solid #c0c0c0 !important;}
/*step form*/
.iXwGIs{ margin: 0 auto !important;}
.MultiFrom > div > div:nth-child(1){ padding: 0 65px;}
.dfUbuK {
    margin: 0 !important;
}
.iMLrQI{ background-color:#023A51 !important }
.imfLnT{ background-color:#023A51 !important }
.gABUNz{ color:#023A51 !important; background-color:#023A51 !important}
.gABUNz:after{ color:#023A51 !important; font-weight: 900 !important;     bottom: -25px !important; font-size: 13px !important; text-transform: capitalize !important;}
.imfLnT:after{ color:#023A51 !important; font-weight: 900 !important;     bottom: -25px !important; font-size: 13px !important; text-transform: capitalize !important;}
.lhPIJq {color:#023A51 !important; background-color:#ba4706 !important}
.lhPIJq:after{ color:#ba4706 !important; font-weight: 900 !important;     bottom: -25px !important; font-size: 13px !important; text-transform: capitalize !important;}
.ijzbgW{background-color:#023A51 !important}
.css-14el2xx-placeholder{ font-size: 14px;}

.headerNav ul li .introjs-showElement{ background-color: #fff; color: #ba4706; font-weight: 600; }

body .introjs-tooltip{ min-width: 300px;}
body .introjs-bullets { display: none !important;}
body .introjs-tooltip-header { padding-left: 15px; padding-right: 15px;}
body .introjs-tooltiptext { padding: 10px 15px 15px;}
body .introjs-tooltipbuttons{ padding: 15px;}
body .introjs-button{ padding: .3rem 1rem;}
body .introjs-nextbutton{
    color:#fff !important; background-color:#ba4706 !important;
     border: 1px solid #ba4706 !important; outline: 0 !important;
     text-shadow: none !important;
}
body .introjs-nextbutton:hover{   border: 1px solid #ba4706;}
body .introjs-nextbutton:active{ border: 1px solid #ba4706;}
body .introjs-button:focus{ box-shadow: none !important;}

body .introjs-prevbutton{ color:#fff !important; background-color:#023A51 !important;
    border: 1px solid #023A51 !important; outline: 0 !important;
    text-shadow: none !important;}
    body .introjs-prevbutton.introjs-disabled{
        color: #9e9e9e !important;
        border-color: #bdbdbd !important;
        box-shadow: none;
        cursor: default;
        background-color: #f4f4f4 !important
}

.reportList ul li{  padding: 16px 0; border-bottom: 1px solid #cdcdcd;}
.reportList ul li:nth-child(1){ padding-top: 0;}
.reportList ul li:last-child{ border-bottom: 0;}
.reportList ul li a{ font-weight: 500; font-size: 16px;}
body .introjs-tooltipReferenceLayer{ width: 100% !important; height: 100% !important;}
.main-tooltip{ left: 45%; top: 10%; margin-top: 0 !important;}

.react-joyride__tooltip h4{font-size: 20px !important;}
.react-joyride__tooltip h4 + div{ opacity: .8;}
.approvalScroll .overflow-x-auto{ height: 197px; overflow-y: auto;}
.OpenMeassage{border: 1px dashed #cdcdcd;
    margin: 20px; background: #f7f7f7;
    padding: 16px;
    border-radius: 8px;}
    .searchSec {    max-width: 230px;
        min-width: 230px}
#address{ margin-bottom: 0 !important;     min-height: 42px;   border-radius: 4px; background: #F9F9F9;}
#address:focus{ outline: 1px solid #023A51 !important; background: #fff; border: 0;}
.file-upload {
    height: 100%;
    border: 0;
    width: 100%; 
    border-radius: 4px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #023a51ab;
    overflow: hidden;
    background-size: 100% 200%;
    transition: all 1s;
    color: #fff;
    font-size:30px;
    top: 50%;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


#popup-root .popUp480-content{     max-width: 420px !important;}
.barChartArea{ max-height: 180px;}
.ChartDiv .tabContent{ height: auto;}
.barChartArea canvas{ width: 100% !important;}
.absenseWeek{ max-height: 100px; overflow-y: auto;}

.workingSchduleForm .react-datepicker-wrapper{ width: auto;}
.workingSchduleForm .reactDatePickerCss {
    width: 80px !important;
    border: 1px solid #e6e6e6;
    padding: 5px 6px;
    border-radius: 4px;
   
}

/* .workingSchduleForm .react-datepicker-wrapper .react-datepicker__tab-loop .react-datepicker-popper1 .react-datepicker__triangle {
    left : 30px !important;
    
} */

.file_Upload{
    border: 1px dashed #cdcdcd;
    margin-bottom: 12px; position: relative;
    border-radius: 4px; padding: 24px;
    text-align: center;     cursor: pointer;
}
.file_Upload p{ margin: 0 !important; pointer-events: none;}

.file_Upload input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}
.selectedCard{ position: relative; }
.checkIcon{    display: none;
    width: 30px;
    height: 30px;
    background: #fff;
    color: #ba4706;
    text-align: center;
    line-height: 30px;
    font-size: 24px;
    position: absolute;
    top: 15px;
    right: 20px;}
.selectedCard input{ position: absolute; cursor: pointer; opacity: 0; left: 0; z-index: 11111; right: 0; top: 0; position: absolute; width: 100%; height: 100%;}
.selectedCard input:checked + div{

    background: linear-gradient(45deg, #ba4706, #ec9566) !important; 

}
.selectedCard input:checked + div .checkIcon{ display: block;}

#popup-root .popup-content.creditCard-content{ max-width: 580px;}
.Department__option{ font-weight: 300;   padding: 3px 14px !important;   font-size: 14px !important; }
.LeaveType__option{ font-weight: 300;   padding: 3px 14px !important;   font-size: 14px !important; }
.Department__option:hover{  background: #023A51; color: #fff;}
.LeaveType__option--is-focused{  background: #023A51 !important; color: #fff !important;}
.LeaveType__option--is-selected:active{  background: #023A51 !important; color: #fff !important;}
.LeaveType__option:hover{  background: #023A51; color: #fff;}

.Department__option--is-focused{  background: #023A51 !important; color: #fff !important;}
.Department__option--is-selected:active{  background: #023A51 !important; color: #fff !important;}
.Department__option:hover{  background: #023A51; color: #fff;}
/* .LeaveType__option--is-selected{  background: #023A51 !important; color: #fff !important;} */
.basic-multi-select .LeaveType__menu-list span.fa{ font-size: 12px;     padding: 4px 4px;
    background: #e1f6ff;
    border-radius: 4px;
    color: #023A51; }
    .basic-multi-select .LeaveType__menu-list span.fas{ font-size: 12px;     padding: 4px 4px;
        background: #e1f6ff;
        border-radius: 4px;
        color: #023A51; }

.basic-multi-select .LeaveType__menu-list span.fa+span{ padding-left: 5px;}
.basic-multi-select .Nondeductible + span{ pointer-events: none; font-weight: 500; width: 100%; font-size: 14px; cursor: none; pointer-events: none;    margin-left: 0 !important;}
.basic-multi-select .Nondeductible { pointer-events: none; font-weight: 500;  cursor: none; pointer-events: none;    margin-left: 0 !important;}
/* .LeaveType__option:has(.Nondeductible){
pointer-events: none; cursor: none;
} */
.LeaveType__option{ position: relative;}
/* .basic-multi-select .Nondeductible + span:after{content: ""; z-index: 99999;
  height: 100%;
  cursor: none;
  pointer-events: none; background-color: #001c44;
 position: absolute; left: 0; right: 0; width: 100%; z-index: 222;}
 */



.basic-multi-select .css-tr4s17-option{     background: #fff;  color: #000;     padding: 12px 12px;  border-bottom: 0px solid #eeeeee;}
.takesLeave p{ display: flex; justify-content: center; width: 100%; align-items: center;}
.leaveDay{margin: 0 5px;  width: 30px; height: 30px; background-color: #ba4706; border-radius: 50%; color: #fff; text-align: center ; line-height: 30px; }
.leaveDayAdd{margin: 0 5px; display: inline-flex; justify-content: center; align-items: center; width: 40px; height: 40px; background-color: #ba4706; border-radius: 50%; color: #fff; text-align: center ; line-height: 30px; }
.bellCount{
    background: #ba4706;
    position: absolute;
    top: -4px;
    right: -4px;
    display: block;
    width: 25px;
    height: 25px;
    color: #fff;
    border-radius: 50%;
    font-size: 11px;
    line-height: 25px;
}
.approvalsCount{
    background: #ba4706;
    position: absolute;
    top: -6px;
    right: -10px;
    display: block;
    width: 25px;
    height: 25px;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
}

.ExpenseAfterUplaod ul li{ margin-bottom: 16px;
    
}
.ExpenseAfterUplaod ul li span{ margin-left: 10px;}
.empDashboard .infoList .p-2{ max-height: 250px; overflow-y: auto;}
.notification ul{ max-height: 300px; overflow-y: auto;}
.cancelBtn{     color: #de0000; border: 1px solid #de0000;}
.cancelBtn:hover{     color: #fff; border: 1px solid #de0000; background: #de0000;} 
.Department__placeholder{ font-size: 14px !important;}
.LeaveType__placeholder{ font-size: 14px !important;}
.formBlock span.fa-asterisk	{font-size: 8px !important; } 

/* .LeaveType__menu-list #react-select-7-option-0{ color: #000;  pointer-events: none; cursor: none; font-size: 15px !important; font-weight: 500; padding-top: 8px !important;}
.LeaveType__menu-list #react-select-7-option-8{ color: #000; pointer-events: none; cursor: none; font-size: 15px !important; font-weight: 500; padding-top: 12px !important;}
.LeaveType__menu-list #react-select-3-option-0{ color: #000; pointer-events: none; cursor: none; font-size: 15px !important;font-weight: 500; padding-top: 8px !important;}
.LeaveType__menu-list #react-select-3-option-8{ color: #000; pointer-events: none; cursor: none; font-size: 15px !important;  font-weight: 500; padding-top: 12px !important;}  */
.employeePage .searchSec select {
    width: 100%;
}
.approvalPage .searchSec select {
    width: 100%;
}
.exportFile a{height: auto !important;}
.view_tbl_exp{ white-space: nowrap;  overflow-y: auto;}
.importPopup-content .content{ overflow-y: hidden;}

body select option:focus {
    background-color: lime !important;
    box-shadow: 0 0 10px 100px yellow inset !important;
  } 
select.formControl option:hover{
      background-color: #000 !important;
   
}
.leaveCalendar .card{ min-height: 580px;}
.headingHeight .heading_title{    min-height: 57px;}

.empDashboard .infoList{ min-height: 220px;}
.empDashboard .infoList .heading_title h3{    line-height: 23px;}
.MyProfile{ width: 32px; height: 32px;   border-radius: 50%; }
.MyProfile img{ width: 100%; height: 100%; object-fit: cover;   border-radius: 50%;  border: 2px solid #fff; }

/**/
#popup-root .bookTimeLeave-content {  max-width: 540px;}
#popup-root .leavePopup-content {  max-width: 540px;}
.stepIndicator ul li span{ width: 24px; height: 24px; background: #9d9d9d; color: #fff; display: flex; align-items: center; justify-content: center; border-radius: 50%;}
.stepIndicator ul { display: flex; justify-content: space-between;}
.stepIndicator ul li{     border-bottom: 1px solid #adadad; width: 50%; padding: 12px 25px; font-size: 16px; display: flex; align-items: baseline; gap: 8px;}
.stepIndicator ul li.active { color: #023A51; font-weight: 600;  border-bottom: 1px solid #023A51;}
.stepIndicator ul li.active span{ background: #023A51;}
.stepIndicator ul li:nth-child(2){ padding-left: 0;}
.changeDown{    background: #f0d4c7; border-radius: 50%;  padding: 4px;  font-size: 14px !important;  color: #ba4706;}
.restrictedDay .restricteSpan{ padding: 8px 12px; margin-top: 8px;}
#popup-root .popup-content.proRata-content{ max-width: 480px;}
.bhIcon{ background-color: rgb(221, 173, 175); cursor: pointer;}
.bhIcon + span{ display: none !important;}
.bhIcon:hover{ background-color:rgb(228, 227, 228)}
.leavePopup-content select.formControl{      font-weight: 600;   background: #fff; color: #023a51;     border: 1px solid #96c0d2;}
.bgBlueLight{ background: #dfecf1;}
.shadowblue{ box-shadow: 0px 1px 6px #2b738e9e;}
.shadowsecondry{ box-shadow: 0px 1px 6px #ba4706c2;}
.timeDiv{ font-size: 0.875rem;  font-weight: 600; gap: 12px; border-radius: 0.25rem;  width: 100%; min-height: 42px; border: 1px solid #2b738e9e; display: flex; align-items: center; justify-content: center;}
.timeDiv .fa-clock{ font-size: 20px;}


.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{

    left: -30px !important;

}

.fa-b{ font-weight: normal !important;}
.fa-b:before{ content: "BH" !important;  font-family: 'Poppins', sans-serif; letter-spacing: 1.1px;     font-weight: bold;}
.an-simple-select__option.an-simple-select__option--is-selected {
    background-color: white;
    color: red;
  }
  .an-simple-select__option:hover {
    background-color: green;
  }
  .react-datepicker{ font-size: 14px !important;}
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{  padding: 8px 0;  }
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select select{padding: 6px 12px;}
  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-width: 4px 4px 0 0 !important;     
    height: 12px !important;  
    top: 6px !important;
    width: 12px !important; 
  }
.LeaveType__dropdown-indicator{ position: absolute;  left: 0;  width: 100%;  display: flex;  justify-content: flex-end;
    border: 0;}
.LeaveType__indicator-separator{ position: relative;  right: 40px;}

/* .headerNav ul li.navpad{ margin-right: 0;}
.headerNav ul li.navpad+li{margin-right: 0; }
.headerNav ul li.navpad + li + li{margin-right: 0; }
.headerNav ul li.navpad a{ padding:8.5px 6px}
.headerNav ul li.navpad+li a{padding:8.5px 6px} */
.headerNav ul li.navpad a{ white-space: nowrap;}
.headerNav ul li.navpad + li + li a{ white-space: nowrap;}
.employeeRight h3{ text-transform: capitalize;}
#fullCalendarClose + .header{ padding: 16px 16px;}
.tdMaxWidth2{  max-width: 300px;  overflow: scroll;}
/* .header span:empty{ display: none;} */

.primary-text-msg {
  color: blue; /* Initial color */
  cursor: pointer;
}

.primary-text-msg:hover {
  color: rgb(114, 109, 197); /* Color on hover */
}
.defaultLogo{
    height: 60px;
    width: 60px;
}
.documentPopupList ul li{ font-size: 14px; font-weight: 500;  padding: 10px 16px; display: flex; align-items: center; gap: 10px;}
.documentPopupList{ position: absolute;  top: 0; background: #ffff; box-shadow: 1px 5px 4px #0000000a;  right: 50%;  left: auto; z-index: 255; display: none;}

.hoverIcon:hover .documentPopupList{  display: block;}
.hoverIcon > span{ font-size: 18px; line-height: 1; }
.documentPopupList ul li span{ font-size: 18px;}
.documentPopupList ul li:last-child{ border-bottom: 0;}
.documentPopupList ul li a{ color: #023A51; display: flex;  gap: 10px; font-size: 14px; }
.hoverIcon {color: #023A51;}

.file-preview{ width: 80px; position: relative;}
.file-preview img{ height:70px ; object-fit: cover; width: 100%; border:1px solid #cdcdcd }
.filePreview{  height:70px ;  position: relative; border-radius: 4px; border:1px solid #cdcdcd; display: flex;
    align-items: center;  justify-content: center;}

.removeIconImg { display: none;   position: absolute;  top: 50%;   left: 50%;  right: 0;  margin: 0;  transform: translate(-50%, -50%);
    background: #ffffffb5;  border-radius: 50%;  width: 40px; height: 40px;  padding: 0;  }
.file-preview:hover .removeIconImg{ display: block;}
.file-preview p{ text-overflow: ellipsis; white-space: nowrap; overflow: hidden;  width: calc(100% - 10px);}

.employeeDocument .tabCard .modal p{ text-overflow: ellipsis;  white-space: nowrap; overflow: hidden;}

.Toastify__toast-container .Toastify__toast{ margin-top: 112px;}
/* #root .Toastify__toast-container--top-center{ left: 70% !important;} */


.iconselect__menu-list { display: flex; flex-wrap: wrap;  max-height: 140px !important; }
.iconselect__menu-list .iconselect__option{ width: 16.66% !important; text-align: center;}
.loginGradient{
    height: 100vh;
    min-height: 100%; 
    position: fixed; 
    background-position: center center;
    overflow-y: auto;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /* background: linear-gradient(318deg, rgba(11,9,44,1) 0%, rgba(132,1,9,1) 50%, rgba(247,118,5,1) 100%); */
}

    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
    }

.importSuccessPopup-content .view_tbl_exp{ height: 480px;     padding: 25px;}
.applyAllDay label{  font-weight: 500;}
.applyAllDay{ display: none;}
.workingSchduleForm .workDays:nth-child(2) .applyAllDay{ display: block;}
.Select__menu-list--is-multi{ max-height: 180px !important;}
.Select__control--menu-is-open{ margin-bottom: 180px !important;}

    @media (max-width: 1800px){
        #signupForm {
            height: 400px;
            overflow-y: auto;
        }
    }




@media (min-width:1480px){
    /* #popup-root .popup-content{     max-width: 820px;} */
    #popup-root .onboardPopup-content {
    max-width: 1240px !important;
}
#popup-root .bookTimeLeave-content {  max-width: 540px;}
#popup-root .leavePopup-content {  max-width: 540px;}

}

@media (min-width: 1321px){
    .container {
        max-width: 1270px;
    }
    /* .tabList{ min-width: 287px;} */

    }


    @media (min-width: 992px){
      
        .fc-multimonth-month {
            width: 33% !important;     padding: 0 12px 12px ;
          }
          .fc .fc-view-harness-active>.fc-view{ padding-bottom: 10px;}
          .fc .fc-multimonth{ justify-content: space-between;}
          .fc-multimonth-header-table {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
          .fc .fc-multimonth-singlecol .fc-multimonth-daygrid-table, .fc .fc-multimonth-singlecol .fc-multimonth-header-table {
            border-left-style: dashed;
            border-right-style: dashed;
        }

          /* .fc .fc-multimonth-singlecol .fc-multimonth-daygrid{

            border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
          } */
          /* .fc .fc-multimonth-daygrid {
            background: #fff;
            border: 1px solid #ddd;
        }
        .fc-scrollgrid-sync-inner{
            border: 1px solid #ddd;
        }
          .fc-theme-standard td, .fc-theme-standard th {
            border: 0;
        }  */
        }
    
    @media (min-width: 1500px){
        .container {
            max-width: 1320px !important;
        }

        /* .claimOvertimePopup-content .modal > .content{ max-height: max-content;} */
        /* .claimMileagePopup-content .modal > .content{ max-height: max-content;} */
        /* .claimExpensePopup-content .modal > .content{ max-height: max-content;} */


        }
        @media (min-width: 1800px){
            .container {
                max-width: 1620px !important;
            }
            }
            @media (min-width: 479px){
             
            .employeeRight { display: flex;
                align-items: flex-start;
                justify-content: space-between;
                }
                .employeeRight .employeeContent{ width: 50%;}
            }
            @media (max-width: 1440px){
            #signupForm {
                height: 330px;
                overflow-y: auto;
            }
            .leaveCalendarAdd-content .modal > .content{ max-height: 480px;}
        }

 @media (max-width: 1320px){
        .container {
     max-width: 100%;
   padding: 0 20px;
  }
  .logo{ width: 220px;}
 }
 @media (max-width: 1280px) {
    #signupForm{ height: 400px; overflow-y: auto;}
    .searchSec {
        max-width: 220px;
        min-width: 220px;
    }
 }
 @media (max-width: 1220px) {

    .searchSec{min-width:initial}
    .searchSec select{ width: 100%;}
    #popup-root .onboardPopup-content{ max-width: 90% !important;}
    /* #root .Toastify__toast-container--top-center{ left: 50% !important;} */
    .Toastify__toast-container .Toastify__toast{ margin-top: 115px;}
 }
 @media (max-width: 1170px){
    .logo{ width: 180px;}   
    .infoList .heading_title h3{ font-size: 13px;}
.headerNav ul li a {
    padding: 8.5px 8px;}
    .headerNav ul li { margin-right: 6px;}
    #loginForm { height: 300px; overflow-y: auto;}
    #signupForm{ height: 300px; overflow-y: auto;}
    .leaveCalendarAdd-content .modal > .content{ max-height: 380px;}
 }
 @media (max-width: 1120px) {

    .headerNav ul li { margin-right: 0.4rem;}
    #popup-root .popup-content{ width: 100%;}
    .logo{ width: 170px;} 
    .headerNav ul li a { font-size: 13px; padding: 8.5px 7px;}
    .currentYear p{ min-height: 45px;}
 }


@media (max-width: 1080px) {

    .container{ padding: 0 15px; }    
    .logo{ width: 160px;     margin-right: 16px;}
    .headerNav ul li { margin-right: 0.4rem;}
    .infoList .heading_title h3{ font-size: 12px;}


}

@media (max-width: 1023px) {
    .toggleIcon span{ font-size: 22px !important;}
    .container{ padding: 0 15px; max-width: 100%;}
    .headerNav ul li{ margin-right: 0;}
    .headerNav ul li a{ display: block;   color: #fff; padding: 1rem 0.5rem;}
    .headerNav{height: 100%; z-index: 2222; left: -100%;     box-shadow: 1px 0px 10px #00000036;     -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        padding:24px;  overflow-y: auto; position: fixed; min-width: 280px; top: 0; background: #ba4706;}
    .headerNav.show{ left: 0; transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;}    
    .tabList ul li a{ padding: 10px 32px; font-size: 14px; }
    .exportFile{ margin-top: 8px;}
    .loginImg{ height: 500px; object-fit: contain;}
    #loginForm { height: 300px; overflow-y: auto;}
    .loginImage p br{ display: none;}
    .loginImage .userImage {width: 310px;}
    .calendarContent{ margin-top: 24px;}
    
    
}
@media (max-width: 991px) {

 .infoList .heading_title h3{ font-size: 14px;}
 .currentYear p{ min-height: inherit;}
 #popup-root .popup-content {  max-width: 680px;  width: 100%;}
.employeeTab ul li{ margin-right: 18px;}
.employeeTab ul li span{ font-size: 15px;}
.workingSchduleForm span{ text-align: right; font-size: 12px;}
.formControl{ padding: 8px;}
.prev span::after{ left: 0; }
.next span::after{ right: 0;}
.currentYear p{    min-height: 45px;}
#loginForm { height: 300px; overflow-y: auto;}
#signupForm{ height: 300px; overflow-y: auto;}
#popup-root .bookTimeLeave-content {  max-width: 540px;}
#popup-root .leavePopup-content {  max-width: 540px;}

#popup-root .workScheduleOnBoardPopup-content {
    max-width: 90% !important;
}
}

@media (max-width: 799px) {

    .employeeTab ul li{ margin-right: 16px;}
    .employeeTab ul li span{ font-size: 14px;}
}

@media (max-width: 767px) {
    .calculatorTabs ul{ flex-wrap: wrap; padding: 16px;}
    .calculatorTabs ul li { width: 100%;}
    .loginGradient{ height: 100vh;}
    .subHeader img{ max-width: 60px;}
    .headerQuickAdd{  margin-left: auto;}
    .MyProfile{ width: 26px; height: 26px;}
    #loginForm { height: auto; overflow-y: auto;}
    #signupForm{ height: auto; overflow-y: auto;}
    #popup-root .popup-content.smallModal-content { max-width: 90%; }
    #popup-root .onboardPopup-content{ max-width: 90% !important;}
    .onboardingLink ul{ display: flex; flex-wrap: wrap;     margin: 24px 0 0 0;}
    .multiNav{ min-height: auto;}
    .onboardingTab{ min-height: auto;}
    .__floater__open{ left: 50% !important; top: 50% !important;     transform: translate(-50%, -50%) !important;}

    .assignIcon{margin: 1rem 0 2.5rem 0 !important;
        padding: 0 !important;
        line-height: 0;
        transform: rotate(90deg);}
        .popup-content .modal > .content{ max-height: 380px;}
        .employeeTab ul{ flex-wrap: wrap;}
        .calendar table{ width: 100%;}
        .calendarLeft .listImage + div{ display: none;}
        .calendarTable td{ padding: 5px 6px;}
        .myExpensPage .headerButton{ display: flex; justify-content: center; flex-wrap: wrap; width: 100%;}
        .myExpensPage .heading_title { padding: 8px;}
        .btn-md { padding: 4px 6px;}

        .settingTabArea .tabList ul{     display: flex;      flex-wrap: wrap; }
        .tabList ul li a span{ top: 10px;}
        .searchSec{ max-width: 100%;}
        .MultiFrom .sc-bgqQcB:after{ display: none;}
        .restrictedDay .restricteSpan{ font-size: 14px;}
        /* .employeeDocument .headerButton{  width: auto;} */
        .documentPopupList ul li span{ font-size: 14px;}
        .documentPopupList ul li {font-size: 13px;}
        /* .documentPopupList{ left: -20px; width: 100px;} */

   
}
@media (min-width: 482px) and (max-width: 650px) {

    .leaveCalendar .headerButton{ display: flex; flex-wrap: wrap; gap: 4px; justify-content: flex-end}
    .leaveCalendar .headerButton .btn-md{ margin-right: 0 !important;}
}

@media (max-width: 575px) {
    .headerButton .btn-md{     min-width: inherit;  font-size: 14px; }
    .approvalSubTab { flex-wrap: wrap;}
    .approvalSubTab li{ width: 100%; margin-right: 0;}
    .settingTab .heading_title{ justify-content: space-between; text-align: center;}
    .imageUpload{ margin: 0 auto;}
    header{ padding: 0;}
    .logo { width: 130px;  margin-right: 9px;}
    .bell {width: 26px; height: 26px; line-height: 26px;}
    .toggleIcon .text-3xl{ font-size: 1.5rem; margin-right: 0.5rem;}
    .show.headerNav .toggleIcon .text-3xl{ margin-right:0}
    .dropdown img{ width: 26px; height: 26px;}

    .listImage img {  min-width: 30px;  width: 30px;    height: 30px;}

}

@media (max-width: 481px) {
    .employeePage .headerButton{ margin: 16px 0;}
.MultiFrom .hUjTly::after{ display: none;}
.MultiFrom .dTvEF::after{ display: none;}

.tabContent ul li{ padding: 5px 6px;}
.btn{ font-size: 14px;}
.heading_title{ flex-wrap: wrap;}

/* #root .Toastify__toast-container--top-center{ left: auto !important;} */
.headerButton{ width: 100%;}
.headerButton .btn-md {
    min-width: inherit;
   
    font-size: 14px;
    width: auto;
    display: table;
    margin: 0 auto 5px;
}
.employeeDetails { flex-wrap: wrap;}
.employeeImage{ margin: 0 auto 24px;}
.employeeContent{ text-align: center; padding-bottom: 16px;}
.employeeContent h3{ margin-bottom: 8px;}
.employeeRight { display: flex; flex-direction: column;}
.employeeRight .justify-end {
    justify-content: center;
}
.heading_title .btn-md{ width: 100%;}
.BillingDeatils.mt-10.card2.p-5{ margin-top: 0;}
.form-group.pt-4.pb-3.flex.justify-center{     padding-top: 0;}
/* .myExpensPage .headerButton .btn-md{ margin: 2px; display: block;} */
.heading_title .headerButton a{ height: auto !important; width: 100%;}

}

@media (max-width: 414px) {
 .modal > .header{padding: 10px !important; }
 .modal > .content{ padding: 16px !important;}

 
 .file_Upload{     padding: 12px;}
 .heading_title h3{ margin-bottom: 10px;}

 .headerButton .btn-md {   min-width: inherit;
    padding: 3px 8px;
    font-size: 14px;
    width: 100%;
    display: table;
    margin: 0 auto 5px;}
    .exportFile a{ width: 100%;}
    .tabContent .workingSchduleForm p{ font-size: 13px;}
.tabContent .workingSchduleForm input, .workingSchduleForm select{ font-size: 13px;} 
/* .tabContent .workingSchduleForm label{ font-size: 12px;}  */
/* .workingSchduleForm .w-5 {  min-width: 14px !important; max-width: 14px;} */
}


@media (max-width: 375px) {
    #signupForm{ height: 350px; overflow-y: auto;}
.tabContent .workingSchduleForm p{ font-size: 13px;}
.tabContent .workingSchduleForm input, .workingSchduleForm select{ font-size: 13px;} 
/* .tabContent .workingSchduleForm label{ font-size: 12px;}  */
/* .workingSchduleForm .w-5 {  min-width: 14px !important; max-width: 14px;} */

.gABUNz:after { 
    bottom: -35px !important;    
    max-width: 100px;
    text-align: center;
    line-height: 14px;
}

.lhPIJq:after{
    bottom: -35px !important;
    max-width: 100px;
    text-align: center;
    line-height: 14px;
}
.icKmgo:after{
    max-width: 100px;
    text-align: center;
    line-height: 14px;
    bottom: -35px !important;
}
.imfLnT:after{
    max-width: 100px;
    text-align: center;
    line-height: 14px;
    bottom: -35px !important;
}
.MultiFrom > div > div:nth-child(1) {
    padding: 0 32px; margin-bottom: 16px !important;
}



}
   
.fc .fc-button-primary {
    background-color: #023a51 !important;
    border-color: #023a51 !important;     height: auto !important;
}












































































































/* .sc-bcXHqe{     border: 2px dashed #5e1e91 !important;} */
.sc-bcXHqe svg path{ fill: #5e1e91 !important; color: #5e1e91 !important;}
.addressEditRemove{ margin-top: 5px; }
.editCancel i.fa.fa-minus{
    margin-right: 0;
}
.stars span { line-height: 1 !important;}
.imgRadius{ display: flex; align-items: center; justify-content: center;}
.imgRadius img {
    border-radius: 0 !important;
    height: auto !important;
}

.w-140{ margin-right: 10px;  font-weight: 500;}
.css-2b097c-container .css-1wy0on6{ display: none;}
.sidebarnav ul li a i{ font-size: 24px;}
.buttonItem .offPrice {
    background: #f2e9ff;
    border-radius: 8px !important;
    color: #9e6dc8 !important;
    font-size: 12px;
    margin-left: auto;
    padding: 3px 10px !important;
    border: 0 !important;
    min-width: auto !important;
    position: relative;
    top: 1px;
    display: table !important;
}

.timeButtons .owl-carousel .owl-nav button {
    top: 0 !important;
}

.offPrice i {
    margin-right: 5px
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.tox-notifications-container {
    display: none;
}

.tox-statusbar .tox-statusbar__text-container {
    display: none !important;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{ min-height: 28px !important;}
.rbc-calendar{ min-height: 640px;}

.sidebarnav ul li {
    position: relative;
    margin-bottom: 8px;
    cursor: pointer;
}

.sidebarnav ul li span {
    display: none;
    padding-left: 8px;
    margin-left: 0 !important;
}

.sidebarnav ul li span + i {
    display: none;
    position: absolute;
    right: 15px;
    font-size: 16px;
}

.sidebarnav ul li a {
    color: #fff;
    justify-content: center;
    text-align: center;
}

.sidebarnav ul li div {
    justify-content: center;
}

.sidebarnav .togllemin {
    display: none;
}
.min-h-175{min-height: 175px;}

.sidebaropen + .PartnerAdminPortal .PartnerDCard .absolute.right-0 span { font-size: 55px;}
.sidebaropen + .PartnerAdminPortal .dashboardCard.PartnerDashboard h2{ font-size: 32px !important;}
.sidebaropen + .PartnerAdminPortal .dashboardCard.PartnerDashboard h3{ margin-bottom: 10px;}
/* .sidebaropen{  transition-property: min-width;
  transition-duration: 4s;
  transition-delay: 2s;} */
/* .sidebaropen.sidebarnav ul li:hover span {   color: #bf72ff;}
.sidebaropen.sidebarnav ul li:hover span {   color: #bf72ff;} */
.sidebaropen.sidebarnav {
    min-width: 260px;
}

.sidebaropen.sidebarnav ul li span {
    display: block;
}

.sidebaropen.sidebarnav ul li span + i {
    display: block;
}

/* .sidebaropen.sidebarnav .togllefull{ display: none;} */
.sidebaropen.sidebarnav .togllemin {
    display: block;
}

.sidebaropen.sidebarnav ul li a {
    justify-content: flex-start;
}

.sidebaropen.sidebarnav ul li div {
    justify-content: flex-start;
}

.togllefull i {
    cursor: pointer;
}

.togllemin i {
    cursor: pointer;
}

.datatoggle {
    cursor: pointer;
}

.dashboardCard h2 {
    font-size: 36px;
}

.contentArea .dashboardCard {
    z-index: 1;
}

.bg-gray {
    background: #f7f5f9
}

.bg-blue-500 {
    background: #6d9eeb
}

.tick {
    background-color: #39ab47;
    color: #fff;
    margin-right: 10px;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
}

/* .css-b62m3t-container{ margin-left: 15px;} */

.addCustomerBtn{ display: flex;}
/* .contentArea {
    width: calc(100% - 60px);
}
.contentArea.PartnerAdminPortal{
    width: calc(100% - 60px);
} */

.sidebaropen + .contentArea.PartnerAdminPortal {
    width: calc(100% - 220px);
}
.sidebaropen + .contentArea {
    width: calc(100% - 220px);
}

.sidebarnav .slidecontent {
    padding: 0px;
    background: #8437c3;
    position: absolute;
    left: 110%;
    width: 190px;
    top: 0;
}

.sidebarul li:nth-child(6) .slidecontent {
    height: 240px;
    overflow-y: auto;
}

.sidebarnav .slidecontent::-webkit-scrollbar:vertical {
    width: 3px;
}

.sidebarnav .slidecontent::-webkit-scrollbar:horizontal {
    height: 3px;
}

.sidebaropen.sidebarnav .slidecontent {
    padding: 0px 0px 0px 0px;
    background: #07254f;
    position: inherit;
    left: 0;
    width: auto;
    height: auto;
    overflow-y: inherit;
}

.sidebarnav .slidecontent li a {
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #092853;
    padding: 10px;
    margin-bottom: 0;
    display: block;
    text-align: left;

}

.sidebaropen.sidebarnav .slidecontent li a {
    padding: 10px 0 10px 50px;
    display: block;
    text-align: left;
}

.sidebarnav .slidecontent li:last-child {
    border-bottom: 0px solid #8437c3;
    margin-bottom: 0;
}


.dashboardCard .rounded-full img {
    border-radius: 0;
    height: auto;
}

.rounded-full img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.file-upload input[type='file'] {
    height: 100%;
    width:100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;    
    cursor: pointer;
}

.formControl {
 
    background-color: #F9F9F9;
    background: #F9F9F9;;
}

.contentArea .css-1s2u09g-control{
    border: 1px solid #e3e3e3 !important;
 
    background: #F9F9F9; min-height: 46px;
}


.colorpick-eyedropper-input-trigger {
    display: none;
}

.imageButtonRadius {
    position: absolute;
    right: 0;
    top: 0;
}
.imageView {
    width: 150px;
    height: 150px;
}
.imageView.w-20 {
    width: 120px;
    height: 120px;
}

.imageButtonRadius button {
    width: 30px;
    height: 30px;
    color: #fff;
    border: 2px solid #be7dfa;
    position: relative;
    margin: 0 5px;
    padding: 20px;
    background-color: #be7dfa;
}

.imageButtonRadius button i {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.imageButtonRadius .btn_circle {
    border-radius: 50%;
}

.imageButtonRadius .btn_rectangle {
    border-radius: 6px;
    width: 70px;
}

.imageButtonRadius .btn_square {
    border-radius: 6px;
}

.imageView.image_circle img {
    border-radius: 50%;
    width: 100%;
}

.imageView.image_rectangle {
    width: 250px;
    border-radius: 6px;
}

.imageView.image_rectangle img {
    border-radius: 0;
    height: 100%;
    width: 100%;
}

.imageView.image_square {
    border-radius: 6px;
}

.imageView.image_square img {
    width: 150px;
    height: 100%;
    border-radius: 0;
}

.imageView img{ width: 100%;}
.appointmentol ol li a {
    color: #802bcb;
}

.appointmentol ol li:hover a {
    color: #420a74;
}

#bannerAdd {
    display: inline-block;
    width: 100%;
    position: relative;
}

#bannerAdd .imageButtonRadius {
    top: -20px;
}

/* .countryCode {background: url(../public/images/qat.svg); background-repeat: no-repeat; padding-left: 70px;
    background-position: 3%; background-size: 30px;} */



#popup-root .popup-content .chip {
    background: #a855f7;
}

#popup-root .popup-content .form-group button {
    font-size: 16px;
}

.multiSelectContainer input {
    margin-top: 4px;
    background: transparent;
    font-size: 16px;
    margin-left: 10px;
}

.modal {
    font-size: 12px;
}

.modal > .header {
    width: 100%;
    border-bottom: 1px solid #001c441f;
    font-size: 18px;

    padding: 16px 25px;
}

.modal > .content {
    width: 100%;
    padding: 25px;
    max-height: 560px;
    overflow-y: auto;
}

.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;

}

.modal > .close {
    cursor: pointer;
    position: absolute;
    outline: 0;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 5px;
    top: 5px;
    font-size: 36px;
    opacity: .5;
    z-index: 1;
}
.modal > .close:hover{ color:#023A51; opacity:1}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 15px;
}

::-webkit-scrollbar:horizontal {
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #afafaf;
}

select.formControl::-webkit-scrollbar {
    width: 6px;
   
  }
::-webkit-scrollbar-track {

    background-color: #fff;
}



.react-datepicker-popper1[data-placement^=bottom] .react-datepicker__triangle::after{
    left: 6px !important;    top: 1px !important;
}
     
.react-datepicker-popper1[data-placement^=bottom] .react-datepicker__triangle::before {
 top: 0px !important; left: 6px !important;
      
 }
 .react-datepicker-popper1[data-placement^=top] .react-datepicker__triangle::after{

    left: 6px !important;
 }
 .react-datepicker-popper1[data-placement^=top] .react-datepicker__triangle::before{
    left: 6px !important;
 }
.css-yk16xz-control {

    border-color: #eaeaea !important;
    box-shadow: 3px 4px 4px #802bca14;
    min-height: 46px !important;
}


.css-1pahdxg-control {
    min-height: 48px !important;
}

.AddappointmentForm .css-1pahdxg-control {
    border-color: #eaeaea !important;
    box-shadow: 3px 4px 4px #802bca14 !important;
}

.AddappointmentForm .css-1pahdxg-control:hover {
    box-shadow: 3px 4px 4px #802bca14 !important;
    border-color: #eaeaea !important;
}

thead .font-normal {
    font-weight: 600;
}


.appointmentol li {
    padding: 15px 0;
    border-bottom: 1px solid #cdcdcd;
}

.appointmentol li:last-child {
    border-bottom: 0;
}

ol {
    list-style-type: decimal;
    padding-left: 18px;
}

ol li span {
    padding: 0 5px;
}

.viewAll {
    text-align: right;
    float: right;
    color: #6722a1;
    font-weight: 600;
}



.react-datepicker__view-calendar-icon input:before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    position: absolute;
    top: 12px;
    right: 18px;
    font-size: 20px;
    color: #c3c3c3;
}
/*input[type="date"]*/

input[type="date"] {
    position: relative;
    padding: 10px;
 
    -webkit-appearance: none;
    appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

select {
    position: relative;
}

select::after {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    top: 12px;
    right: 18px;
    font-size: 20px;
    color: #c3c3c3;
}

.searchWrapper::after {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 18px;
    color: #000;
}

input[type="date"]:before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    position: absolute;
    top: 12px;
    right: 18px;
    font-size: 20px;
    color: #c3c3c3;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


input[type="datetime-local"] {
    position: relative;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="-local"]:before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    position: absolute;
    top: 12px;
    right: 18px;
    font-size: 20px;
    color: #c3c3c3;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type="time"] {
    position: relative;
    padding: 10px;
    background-color: #fff;
    -webkit-appearance: none;
    appearance: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="time"]:before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f017';
    position: absolute;
    top: 12px;
    right: 18px;
    font-size: 20px;
    color: #c3c3c3;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


input[type="time"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none;
}

#thankyou{ display: flex; align-items: center; justify-content: center;}

.thankyou{ background-color: #fff;  flex-direction: column; padding: 25px; border-radius: 10px; display: flex; max-width: 600px; width: 95%; align-items: center; flex-wrap: wrap;}
.thankyou img{ max-width:120px; position: absolute; top: 30px; padding: 5px;  background: #fff; border-radius: 50%;}
.thankyou h3{ width: 100%;  margin-bottom: 10px;  font-size: 22px;   font-weight: 500; }
.thankyou i{ font-size: 40px;
    background-color: #39ab6c;
    margin: 90px 0 30px 0;
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    line-height: 80px;}





    .professionalSec .owl-theme .owl-nav [class*='owl-']:hover {
        background: #9518d8 !important;
        color: #FFF;
    }
    .professionalSec .owl-theme .owl-nav [class*='owl-'] {
        color: #FFF;
        font-size: 14px;
        margin: 5px;
        padding: 4px 7px !important;
        background: #9518d8 !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;top: 34%;
    }
  
    .professionalSec input{opacity:0; position: absolute; height: 100%; width: 100%;}
    .professionalSec input[type=checkbox]:checked~.serviceSlider {
        border: 1px solid #3d1c6f;
      }
      .professionalSec input[type=radio]:checked~.serviceSlider {
        border: 1px solid #3d1c6f;
      }
    
    .professionalSec .serviceSlider:focus{ border: 1px solid #000;}
    .professionalSec{ margin-bottom: 30px; margin-top: 32px;}
    .professionalSec .serviceSlider img{ width: 50px !important;}
    .professionalSec .serviceSlider{ min-height: 180px; text-align: center; background: none; border:1px solid #d6d6d6; padding: 10px; border-radius: 6px;}
    .professionalSec h3{ margin-bottom: 3px;}
    .professionalSec .serviceSlider:hover{ background:#f2e8ff; border: 1px solid #3d1c6f;}
    .professionalSec .serviceSlider:hover p{ color: #51565e;}
    .professionalSec .serviceSlider p { min-height:auto; font-size: 14px; line-height: 22px;}
    .professionalSec .serviceSlider h3{ font-size: 14px; color: #3d1c6f; margin-bottom: 10px;}
    .professionalSec .serviceSlider h3 i{ color:#9e6dc8; padding-left: 5px;}
    .professionalSec .serviceSlider span{     width: 60px;    height:60px; display: inline-flex; justify-content: center; align-items: center;
        margin: 0 auto 15px; background: #fff;border: 1px solid #cdcdcd;border-radius: 50%;}
    .professionalSec .serviceSlider h3 .fa-star:before{ margin-right: 5px;}
    .professionalSec .serviceSlider h6{ font-size: 14px; font-weight: 300; color: #3d1c6f;} 

.filterDiv{ position: absolute; right: 0; padding: 10px; top: 0; z-index: 122;}
.addFilter{ position: fixed; width: 100%; box-shadow: -20px 0 60px 0 rgba(34, 34, 34, 0.623);top: 0; right: 0; -webkit-transform: translate3d(120%, 0, 0);
    transform: translate3d(120%, 0, 0);    transition: transform .8s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform .8s cubic-bezier(0.075, 0.82, 0.165, 1); height: 100%; overflow-y: auto; max-width: 720px; background-color: #fff; z-index: 9999; }
.closeFilter{ padding: 15px 20px; font-size: 20px;  display: flex; align-items: center; justify-content: space-between;}
.closeFilter span{ cursor: pointer;}
.addFilter.show{  -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); transition: transform .8s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform .8s cubic-bezier(0.075, 0.82, 0.165, 1);}
   


    .ReactFlagsSelect-module_selectBtn__19wW7{     border-color: #eaeaea !important;
        box-shadow: 3px 4px 4px #802bca14;}



/*add Appointment*/
.selectService {
    border-color: #eaeaea;
    box-shadow: 3px 4px 4px #802bca14;
    background: #fff;
    border-width: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
    font-size: 0.875rem;
    margin-left: 20px;
    line-height: 1.25rem;
    width: 270px;
}

.appointmentTab {
    background: #6d24ab;
    margin-top: 30px;
    padding: 15px 25px;
}



.appointmentContent {
    padding: 30px;
}

.AddappointmentForm .tabContent {
    border: 1px solid #f3e8ff;
    border-bottom: 0;
}

.AddappointmentForm .tabBtn {
    font-size: 16px;
    color: #fff;
    min-width: inherit;
}

.AppointmentDetailContent {
    padding: 20px;
    background-color: #ebebeb3d;
    border: 1px solid #f3e8ff;
}

.serviceItem {
    padding: 20px 0;
    border-bottom: 1px solid #cdcdcd;
}

.serviceItem h3 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}

.serviceItem:last-child {
    border-bottom: 0;
}

.vertical-center-3 .slick-slide:first-child {
    margin-left: 0;
}

.vertical-center-3 .slick-slide {
    margin-left: 0px;
}

.button-with-counter {
    background: #fff;
    border: 2px solid #432375;
    border-radius: 30px;
    padding: 2px 12px;
    min-width: 90px;
    color: #432375;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

i.fa.fa-minus {
    margin-right: 10px;
}

.cartItemCountBtn.disabled {
    color: #9679c4;
}

/* .button-with-counter.manageCardItemBtnHide {
    display: none !important;
} */

button.btn.btn-primary.addBtn.addItemBtnHide {
    display: none;
}

span.dia.disabled_date {
    background-color: #f3eeee;
    cursor: no-drop !important;
    pointer-events: auto !important;
    color: #b6b6b6 !important;
    border: 2px solid #dbd9df;
}

.button-with-counter span {
    color: #432375;
    font-size: 20px;
    margin-right: 12px;
}

.buttonOpen .addBtn {
    display: none;
}

.buttonOpen .button-with-counter {
    display: flex !important;
}




.amount p {
    color: #3d1c6f;
    font-weight: 600;
}

.amount p span {
    color: #707070;
    font-weight: 300;
    padding-left: 10px;
    text-decoration: line-through;
}


.stepsCard {
    padding: 10px 0 0 0;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
}

.appointmentContent .heading {
    text-align: left;
    margin-bottom: 20px;
}

/* .stepService .card{    min-height: 740px;}
.Payment .card{    min-height: 740px;} */

.actionBTn .btn:disabled {
    opacity: .3;
    background-color: #432375;
    border-color: #432375;
}

.checkboxItem {
    margin-bottom: 20px;
}

.checkboxItem label {
    color: #222222;
    font-weight: 400;
}

.checkboxItem .form-check {
    display: flex;
    align-items: center;
    padding-left: 25px;
}

.checkboxItem .form-check input {
    margin-top: 0;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 50%;
}

.checkboxItem .form-check label {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 18px;
    line-height: 1;
}

.heading h3 {
    font-weight: 600;
}


.checkboxItem p {
    padding-left: 52px;
    color: #707070;
    padding-top: 4px;
}

.checkboxItem .form-check label span {
    background: #f2e9ff;
    margin-left: 10px;
    color: #9e6dc8;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
}

.checkboxItem .form-check label i {
    margin-right: 5px;
}

.actionButton {
    margin-bottom: -60px;
    padding: 30px 20px 60px 20px;
    position: relative;
    top: -115px;
    border-top: 1px solid #cdcdcd;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.actionButton .btn {
    min-width: 154px
}

.card-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 19px 25px;
    border-bottom: 1px solid #cdcdcd;
}

.card-content p {
    margin-bottom: 0;
    color: #222222;
    font-weight: normal;
    line-height: 1;
    font-size: 16px;
}

.card-content p + span {
    color: #707070;
    font-weight: 300;
    width: 100%;
    padding-top: 5px;
}

.card-content ul {
    width: 100%;
    list-style: none;
    margin-top: 15px;
    margin-bottom: 0;
}

.card-content ul li {
    color: #707070;
    font-weight: 300;
    line-height: 37px;
    display: flex;
    justify-content: space-between;
}

.card-content ul li span {
    color: #222222;
}

.card-content ul li .total {
    color: #432375;
    font-weight: 700;
}

.dateTime, .serviceDetails {
    display: none;
}

.card-content:last-child {
    border-bottom: 0;
}

.stepService .heading {
    margin-bottom: 27px;
}

.stepService .heading img {
    margin-left: 5px;
}

.stepService .circleInput label span {
    width: 42px;
    min-width: 42px;
    border-radius: 50%;
}

.stepService .buttonItem label {
    margin-right: 25px !important;
}

.stepService .buttonItem:not(.circleInput) label span {
    padding: 6px 16px;
}

.btn-back {
    border: 0;
    background: none;
    padding: 0;
}

.btn-back:disabled {
    visibility: hidden;
}

.buttonItem textarea {
    border: 2px solid #d6d6d6;
    width: 100%;
    border-radius: 8px;
    min-height: 120px;
    padding: 15px;
}

.creditfirst .form-check {
    padding-left: 0;
}

.creditfirst .form-check h3 img {
    margin-right: 20px;
}

.creditfirst .form-check h3 span {
    margin-left: 10px;
    color: #8753b3;
    font-weight: 300;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}


.form-check-input:checked[type=radio] {
    background: url(https://betaweb.myrighth.com/images/checked.png);
    background-repeat: no-repeat;
    border: 0;
}

.buttonItem label input {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
}

.buttonItem label {
    display: inline-block;
    margin-right: 25px;
}

.owl-item .buttonItem label {
    margin-right: 0;
}

.buttonItem label span {
    color: #51565e;
    cursor: pointer;
    display: block;
    min-width: 125px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 30px;
    text-align: center;
    border: 2px solid #d6d6d6;
}

.buttonItem input:checked + span {
    font-size: 16px;
    font-weight: 500;
}

.buttonItemDate input:checked + span {
    background-color: #432375;
    border: 2px solid #432375;
    color: #fff;
}

.circleInput label span {

    border-radius: 50%;
    min-width: 42px;
    width: 42px;
}


.dia_semana {
    margin-right: 5px;
}

.dia_semana label {
    display: block;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0;
}

.dia_semana label input {
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    left: 0;
}



.dia_semana span {
    display: block;
    padding: 0;
    line-height: 1;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 300;
    color: #707070;
}

.dia_semana .dia {
    text-align: center;
    line-height: 41px;
    color: #51565e;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    font-weight: 500;
    border: 2px solid #d6d6d6;
    margin: 0 auto;
}

.owl-item .mes {
    display: none;
}

.owl-theme .owl-nav {
    color: #8e7daa;
    display: flex;
    justify-content: space-between;
    margin-top: 0 !important;
    width: 100%;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    margin: 0;
    outline: 0 !important;
    position: absolute;
    top: 25%;

}

.owl-nav i {
    font-size: 35px;
    font-weight: 700;
}

.owl-carousel .owl-nav .owl-prev {
    left: -15px;
    right: auto;
}

.owl-carousel .owl-nav .owl-next {
    left: auto;
    right: -15px;
}

.datesec {
    margin-bottom: 20px;
}

.appointmentContent h3 {
    font-weight: 600;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: none !important;
    color: #8e7daa !important;
}

select {
    outline: 0;
}


.togllemin i {
    padding: 0;
    background-color: #fff;
    color: #8437c3;
    font-size: 25px;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-top: 20px;
    line-height: 45px;
}

.errorPage h2 {
    line-height: 80px;
}

.faqtab .tabBtn {
    min-width: auto;
    font-size: 16px;
    font-weight: 600;
    margin-right: 20px;
    color: #707070;
    line-height: 34px;
}

.faqtab {
    border-bottom: 1px solid #e5e7eb;
    text-align: center;
}

.faqtab .tabBtn {
    background: #fff;
    border: 0 !important;
    border-bottom: 1px solid #e5e7eb !important;
    border-radius: 0;
}

.faqtab .tabBtn-active {
    background: #fff;
    color: #432375 !important;
    border: 0 !important;
    border-bottom: 1px solid #60458a !important;
    border-radius: 0;
}

/* npm i react-accessible-accordion */
.faqtab button {
    padding: 10px 20px;
}



.tabContent {
    display: none;
}

.tabContentActive {
    display: block;
}



.tabContent .accordion__button {
    font-weight: 600;
}

.tabContent .accordion__button {
    padding: 25px;
}

.tabContent p {
    margin-bottom: 10px;
}

.tabContent .accordion__panel {
    padding-left: 65px;
}

.accordion__button:before {
    opacity: .7;
    height: 16px;
    width: 16px;
    margin-right: 25px;
}

/* p {
    opacity: .7;
} */

.public-DraftStyleDefault-ltr {
    padding-left: 15px;
}


.react-tel-input .form-control {
    width: 100% !important;
    border-radius: 0.25rem;
    border-width: 1px;
    padding-left: 1rem;
    height: 47px !important;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-color: #eaeaea !important;
    box-shadow: 3px 4px 4px #802bca14;
}

.react-tel-input .flag-dropdown {
    border-color: #eaeaea !important;
}

.help-block.text-red-500 {
    font-size: 13px;
}

.dashboardCard .leading-none {
    line-height: 1.3;
}

.dashboardCard h3 {
    font-size: 22px;
}

.swal2-shown .swal2-popup {
    max-width: 480px !important;
}

.swal2-shown .swal2-title {
    color: #1f1f1f;
    font-weight: 500;
    font-size: 1.5em;
}

.swal2-shown .swal2-html-container {
    font-weight: 300;
}

.swal2-shown .swal2-actions .swal2-styled {
    min-width: 180px;
    border-radius: 30px;
    padding: 12px 30px;
    font-weight: 500;
    font-size: 16px;
    font-weight: normal;
    box-shadow: none !important;
}

.swal2-shown .swal2-styled.swal2-confirm {
    background-color: #432375 !important;
}

.swal2-shown .swal2-icon.swal2-warning {
    border-color: #432375;
    color: #432375;
}

.swal2-shown .swal2-styled.swal2-cancel {
    background: #f2e8ff !important;
    color: #9e6dc8 !important;
}

.swal2-shown .swal2-actions {
    margin: 1.25em auto 1em;
}




.sidebarnav {
    height: auto;
    min-height: 100%;
}

/* .w-100.py-6.md\:px-10.px-3{ padding-left:100px;} */
.btn.bg-purple-500.hover\:bg-gray-500.ml-2 {
    line-height: 1.17;
    cursor: pointer;
}

.imageView {
    object-fit: cover;
}

.multiSelectContainer .chip {
    background: #822cce !important;
}

.searchWrapper {
    border: 1px solid #eaeaea !important;
    min-height: 47px !important;
    box-shadow: 3px 4px 4px #802bca14;
    background: #fff;
}

#appointmentAdd .css-1s2u09g-control {
    border-color: #eaeaea;
    box-shadow: 3px 4px 4px #802bca14;
    min-height: 48px;
}

#appointmentAdd .css-1pahdxg-control {
    min-height: 48px;
}

.AddNewevent .formControl {
    min-height: 46px;
}

.AddNewevent .addbutton {
    min-height: 46px;
}


.rbc-calendar .rbc-toolbar {
    margin: 15px 0;;
}

.rbc-header {
    font-size: 100%;
}

.rbc-calendar .rbc-toolbar button.rbc-active {
    background-color: #023a51;
    border-color: #023a51;
    color: #fff;
}

.rbc-toolbar button.rbc-active:focus, .rbc-toolbar button.rbc-active:hover {
    background-color: #023a51 !important;
    border-color: #023a51 !important;
    color: #fff !important;
}

.radio input {
    margin-right: 8px;
    height: 18px;
    width: 18px;
}

.radio label {
    display: flex;
    line-height: 1.1;
}

.react-datepicker-popper1 {
    z-index: 124;
    width: 100%;
    max-width: 250px;
}

/* .react-datepicker {
    background-color: #e9ddf4;
    padding: 10px;
    overflow-y: auto;
    height: 400px;
}

.react-datepicker button {
    background: #6722a1;
    color: #fff;
    width: 45%;
    font-size: 13px;
    margin: 5px;
}

.react-datepicker__current-month {
    font-weight: bold;
    font-size: 13px;
    padding: 5px 0;
}

.rbc-calendar .rbc-header {
    font-weight: 500;
} */

.contentArea .rbc-agenda-date-cell {
    color: #cdcdcd !important
}

.contentArea .rbc-agenda-event-cell {
    color: #cdcdcd !important
}

.contentArea .rbc-agenda-time-cell {
    color: #cdcdcd !important
}

.appointmentImage {
    padding: 5px;
}

.appointmentImage img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
}

.w-14 {
    min-width: 3.5rem;
}

.pieChart canvas{ width: 100% !important;}
.card .calendar{  width: 100%;  border:0;  height: 100%; border-radius: 8px;}
.react-calendar__month-view__days{ min-height: 280px;}
abbr:where([title]) {  text-decoration: none;}
.react-calendar__tile--active {  background: #9c27b0 !important;}
.filterDropdown{    position: absolute;
    top: 100%;
    padding: 20px; right: 0;
    z-index: 2222;
    background: #f7f7f7;
    border-radius: 10px;
    min-width: 200px;}

.filterDropdown ul li{ margin: 0 0 8px 0; font-size: 14px;}
.filterDropdown ul li input{ margin-right: 5px;}
.filterDropdown ul li:last-child{ margin-bottom: 0;}
.closeButton{ 
    min-width: 20px;
    height: 20px;
    background-color: #9b9b9b;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;
    font-size: 12px;
    position: absolute;
    left: 20px;
    top: 0px; 
}
.filterCard .w-3\/12.pr-4.text-right label{ font-weight: 600;}
.filterCard .formGroup label{ font-size: 14px; ;}
.filterCard .items-center {  align-items: flex-start;}

.filterArea{ text-align: center;  max-width: 250px; float: right;}
.bgLogin::after{
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(167 5 254 / 10%);
    position: absolute;
    top: 0;
}

.iconselect__menu-list { display: flex; flex-wrap: wrap;  max-height: 140px !important; }
.iconselect__menu-list .iconselect__option{ width: 16.66% !important; text-align: center;}


.addressADDelete{ position: relative;}
.addressADDelete p{ width: 80%; padding-right: 10px;} 
.addressADDelete span{ position: absolute; right: 0; top: 0; }
.addressADDelete span i{ width: 26px; font-size: 18px; color: #fff;  padding: 2px; cursor: pointer;
    background: #581c87;  border-radius: 4px;  height: 26px; text-align: center; line-height: 24px;}
.addressADDelete span i.fa-minus{ background: #991b1b;}
.editForm{ max-height: 400px; overflow-y: auto; padding-right: 5px;}

/* .FilterList{ position: absolute; top: 40px; right: 10px; background: #fff; z-index: 111;} */
.FilterList ul{ display: flex; justify-content: flex-start;  flex-wrap: wrap;}
.FilterList li input{ margin-right: 8px; }
.FilterList li { padding: 5px 10px; cursor: pointer; width: 190px; font-size: 14px;}
.FilterList li label { display: block;}
.FilterForm .fa-minus{     width: 20px;  height: 20px;  background: #ef4444;  border-radius: 50%;
    padding: 0;  text-align: center; line-height: 20px;  color: #fff;  min-width: 20px; cursor: pointer;}
.advancedFilter { padding-left: 10px; padding-right: 10px;}
.sort{ position: relative;}
.sort span{ display: none; position: absolute; top: 5px; }
.sort:hover span{ display: inline-block;}

#popup-root .filterPopup-content{ max-width: 920px; width: 100%;}
.filterPopup-content .filterExpandDiv{ max-height: 400px; overflow-y: auto; padding-top: 16px;}
.chartHeight{ height: 400px;}
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  .exportCsvClass:hover {
   color: #fff !important;
  }
  .reactDatePickerCss {
    width: 60px !important;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media (min-width: 1366px) {
    body {
        overflow-x: hidden;
    }

}


/* @media screen and (min-width:1151px) and (max-width: 1150px) {

.card .overflow-x-auto{ white-space: normal;}

}*/


@media screen and (min-width: 1025px) and (max-width: 1150px) {

    /* .w-100.py-6.md\:px-10.px-3{ width: 100%; max-width: 900px;}  */
    /* .card .overflow-x-auto{ white-space: normal;}
    .card .overflow-x-auto tr td .btn.bg-purple-500{     margin: 5px 0 0 0;} */


}


@media screen and (min-width: 1024px) and (max-width: 1280px) {

    .dashboardCard img:not(.cleanerimg img) {
        width: 100px;
        margin-top: 32px;
    }

    .dashboardCard h3 {
        font-size: 16px;
    }

    /* .card {
        white-space: nowrap;
        overflow-x: auto;
    } */
    .appointmentImage img {
        height: 130px;
    }

    /* .contentArea {
        transition: width 0.25s ease, margin 0.25s ease;
        width: calc(100% - 60px);
        min-height: calc(100vh - 70px);
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
    } */

    .sidebaropen + .contentArea {
        width: calc(100% - 220px);
    }

    .react-datepicker button {
        width: 100%;
        margin: 0 auto 5px;
    }

    .react-datepicker-popper1 {
        max-width: 200px;
    }

    .card .lg\:overflow-visible{ overflow: auto;}
}

@media (max-width: 1350px) {

    .startTimesec {
        flex-wrap: wrap;
    }

    .startTime {
        width: 100%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1120px) {

    .react-datepicker-popper1 {
        max-width: 170px;
    }

    .sidebaropen + .contentArea .dashboardCard img:not(.cleanerimg img) {
        width: 70px;
        margin-top: 60px;
    }

}

@media (max-width: 1023px) {
    .sidebaropen + .contentArea.PartnerAdminPortal{ width: 100% !important;}
    .sidebarul li:nth-child(6) .slidecontent {
        height: auto;
    }

    .sidebaropen.sidebarnav .slidecontent {
        height: auto;
    }

    .sidebaropen .fa-close {
        border: 1px solid #935cc2;
        display: table;
        margin: 0 auto;
        padding: 5px 10px;
        border-radius: 50%;
        min-width: 48px;
        min-height: 48px;
        background: #55158b;
    }

    .dashboardCard .md\:w-1\/2 {
        width: 48.5%;
        margin-right: 0;
    }

    .dashboardCard {
        justify-content: space-between;
    }

    .sidebarnav {
        position: absolute;
    }

    .w-100.py-6.md\:px-10.px-3 {
        padding-left: 2.5rem;
    }

    .contentArea {
        width: 100%;
    }

    .appointmentImage img {
        height: 150px;
    }
    .contentArea.PartnerAdminPortal{ width: 100%;}
    
}

@media (max-width: 1023px) {

    .sidebarnav {
        position: fixed;
        height: 100%;
        overflow-y: auto;
    }

    .sidebaropenmob {
        display: block !important;
        visibility: visible !important;
        z-index: 555 !important;
    }

    .dashboardCard h3 {
        font-size: 20px;
    }

    .react-datepicker-popper1 {
        max-width: 150px;
    }

    .react-datepicker button {
        width: 100%;
        margin: 0 auto 5px;
    }

    .sidebaropen + .contentArea {
        width: calc(100% - 0px);
    }

    .AddappointmentForm .tabBtn {
        font-size: 15px;
        padding: 7px 14px;
    }

 
}
@media (min-width: 768px) {
#popup-root .addEmployeee-overlay .popup-content {

    max-width:720px;
}
}

@media (max-width: 767px) {
    .searchSec select{ width: 100%;}
    .pl-4 h3 br{ display: none;}
    #popup-root .popup-content {
        max-width: 480px;
        width: 100%;
    }

    .dashboardCard .md\:w-1\/2 {
        width: 100%;
        margin-right: 0;
    }

    input[type="date"]:before {
        right: 10px;
        font-size: 16px;
    }

    input[type="time"]:before {
        right: 10px;
        font-size: 16px;
    }

    .w-100.py-6.md\:px-10.px-3 {
        padding-left: 12px;
    }

    .AddNewevent {
        flex-wrap: wrap;
    }

    .rbc-btn-group {
        margin: 8px auto 0;
    }

    .react-datepicker-popper1 {
        max-width: 250px;
    }

    .react-datepicker button {
        width: 45%;
        margin: 5px;
    }

    .appointmentTab {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    .appointmentContent {
        padding: 15px;
    }

    .owl-carousel .owl-nav .owl-next {
        right: 0;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 0;
    }

    .closeButton{  margin-top: 5px; right: 0; left: auto;}

    .bgLogin{ background-image: none !important;}
    .bgLogin::after{ display: none;}
    .priceDetailsForm{max-height: 200px; overflow-y: auto; padding-right: 5px;}
    .filterPopup-content .filterExpandDiv{ max-height: inherit; overflow: initial;}
    .filterPopup-overlay .content{ max-height: 300px; overflow-y: auto;}
    .addressEditRemove{ display: block;}
    .editForm { max-height: 180px;}
    #popup-root .max-w-lg-content{ max-width: 90%;}
    .appointmentPopup{ max-height: 200px;}
    .addFilter { padding-bottom: 48px;}
    .iconselect__menu-list {   max-height: 180px !important; }
}

@media (max-width: 575px) {
    .editForm { max-height: 400px;}
    .priceDetailsForm{max-height: 400px;}
    .chartHeight{ height: auto;}
    .sidebaropen.sidebarnav .slidecontent li a{
        padding: 10px 30px 10px 15px;
    }
    #popup-root .popup-content {
        max-width: 90%;
        width: 100%;
    }

    .swal2-shown .swal2-popup {
        max-width: 300px !important;
    }

    .swal2-shown .swal2-actions {
        padding: 0 20px;
    }

    .swal2-shown .swal2-actions .swal2-styled {
        min-width: 100%;
    }

    .rbc-toolbar .rbc-toolbar-label {
        text-align: right !important;
    }

    .appointmentImage img {
        height: 180px;
    }

    .buttonItem label {
        margin-right: 8px;
    }

    .circleInput label span {
        padding: 3px 8px;
        min-width: 34px;
        width: 34px;
        max-height: 34px;
        min-height: 34px;
    }

    .AppointmentDetailContent {
        padding: 8px;
    }

    .addCustomerBtn{ flex-wrap: wrap;}
    .addCustomerBtn a {
        display: table;
        width: auto;
        margin: 0 auto 10px;
        min-width: 200px;
    }

    .addCustomerBtn button {
        display: table;
        width: auto;
        margin: 0 auto 10px;
        min-width: 200px;
    }
    .filterArea{max-width: 100%; float: none; margin-top: 30px;} 
    .filterArea button{ width: 100%; margin: 0 auto 5px !important;}

    .filterPopup-overlay .content{ max-height: 400px; overflow-y: auto;}
    .appointmentPopup{ max-height: 400px;}

  
   
}
@media (max-width: 360px) {
.editCancel{ margin-left: 0 !important; margin-top: 5px;}
}

@media (max-width: 640px) {
    .assignButtons .btn {
        width: auto;
        margin: 0 auto 5px;
        display: table;
    }

    .imageButtonRadius {
        position: static;
        margin: 20px 0;
        width: 100%;
    
    }

}

@media (min-width: 1024px) {
    .tdMaxWidth {
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
   
    

    .cardCalendar{    display: flex;   flex-direction: column;  justify-content: stretch;  min-height: 100%;
        height: 760px;}
}

@media (min-width: 2400px) {

    .cardCalendar{ height: 1060px;}
}

/* :root {
  
    --my-leaveType-icon: fa fa-user;
  }


  .fc .fc-daygrid-event{
    background-color: var(--my-favorite-color) ;
    font: var( --my-leaveType-icon)
  } */

  .myIcon option{ font-weight: 900; }

  /* .fc .fc-multimonth-multicol .fc-multimonth-month {
    width: 33% !important
  } */
  .is-disabled {
    background-color: #A9A9A9;
    
}

/* .fc .fc-popover {
    z-index: 99 !important 
} */
/* #popup-root > div{ z-index: 9999999999999999999 !important;} */



td div .tooltip {
    position: fixed;
    width: 150px;
    z-index: 9999999999999999999999999999999999;
    /* visibility: hidden; */
    opacity: 0;
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
    border-radius: 6px;
    padding: 8px 4px;
    top: 2px;
    left: 100%;
    font-weight: 700;
    font-size: 11px; line-height: 14px;
    background-color: #ba4706;
    color: #ffffff;
}
td:hover{ z-index: 9999 !important;}
.fc .fc-multimonth{ overflow: visible !important;}
.fc .fc-daygrid-body{ position: inherit !important}
td:hover .tooltip{  
    /* visibility: visible; */
    opacity: 1;
}
.calIcon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.calIcon + div .fc-daygrid-day-number{ color: #fff; display: none;}
.calIcon + div .fc-highlight{ background: none !important;}
.fc-daygrid-day {
    height: 22px; /* Adjust the height as needed */
  }

.payBtn {
    background-color: #0f8e53 !important ;
    color: white !important;
    border: none !important;
}

.settingtabs {
    display: flex;
    border-bottom: 2px solid #ccc;
  }
  
  .settingtab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    color: #555;
    transition: color 0.3s ease, border-bottom 0.3s ease;
  }
  
  .settingtab:hover {
    color: #007BFF;
  }
  
  .settingtab.active {
    color: #007BFF;
    border-bottom: 3px solid #007BFF;
  }
  /* Loader CSS */
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #6366f1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
