.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  height: auto;
}
.innerWrapper {
  width: 100%;
  background-color: white;
padding: 0 25px;
  border-radius: 5px;
}
.title {
  width: 100%;
  border-bottom: 1px solid #001c441f;
  font-size: 18px;
  padding: 16px 25px;
  margin-bottom: 35px;
  font-weight: 600;
}
.modal > .close{
  right: 12px;
  top: 12px;
}
.label {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
  
}

.row {
  width: 100%;
  margin-bottom: 24px;
  position: relative;
}
.row label{
  position: absolute;
  top: -10px;
  background: #fff;
  left: 9px;
  padding: 1px 5px;
  font-weight: 500;    color: #3f4254; font-size: 14px;
}
/* .inputLabel {
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin-left: 10px;
  width: 65%;
} */
.inputField {
  width: 100%;
  height: 50px;
  border: 2px solid #e3e3e3;
  padding-left: 10px;
  border-radius: 5px;

  &:focus {
    outline:  0px ;
    border: 2px solid #023A51;
    background-color: #fff;
  }
}
input:-webkit-autofill{
  background-color: #fff !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color: #fff !important;
}
.inputField :-webkit-autofill {
  -webkit-background-clip: text;}
.rowPaymentInput .StripeElement {
  border: 2px solid #cccccc;
  height: 50px;
  padding-top: 14px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 24px;
 
}
.rowPaymentInput .StripeElement--focus{
  outline:  0px ;
  border: 2px solid #023A51;
}
.addressWrapper {
  .rowSelect {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    input {
      width: 100%;
      position: relative;
      border: 2px solid #cccccc;
      padding-left: 10px;
      height: calc(100% - 21px);
      border-radius: 5px;

      &:focus {
        outline: none;
        border: 2px solid #023A51;
      }
    }

    > div {
      width: 50%;
    }
    > div:first-child {
      margin-right: 10px;
    }
  }

  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
  }
}
