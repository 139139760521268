.pagination{
    padding-left:1rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    
}

.pagination button{
    margin-right:0.5rem;
    cursor:pointer;
    padding:7px 15px;
    background: #fdfdfd;
    color: #023a51;
    border: 1px solid #023a51;
    border-radius: 4px;
}

.pagination button:disabled:not(.pagination_next,.pagination_prev){
  
    cursor: not-allowed;
    background-color: #023a51 !important;
    border: 1px solid #023a51;
    color: #fff;

}

.pagination button.pagination_next {
    background: #ba4706;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #fff;
    border: 1px solid #ba4706;
}

.pagination button.pagination_prev {
    background: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #ba4706;
    border: 1px solid #ba4706;
}

.pagination button.pagination_prev:disabled{
    background: #fff;
    margin-bottom: 20px;
    margin-top: 20px; opacity: .5;
    color: #adadad;
    border: 1px solid #adadad;
}
.pagination button.pagination_next:disabled{
    background: #fff;
    margin-bottom: 20px;
    margin-top: 20px; opacity: .5;
    color: #adadad;
    border: 1px solid #adadad;
}

